import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { logEvent } from 'firebase/analytics';
import { Container, TextField, Typography, Box, Card, CardContent, Grid } from '@mui/material';
import BillingInformationForm from '../sections/@dashboard/user/BillingInformationForm';
import ErrorMessage from '../components/alert/ErrorMessage';
import PromptDialog from '../components/dialog/PromptDialog';
import GenericLoader from '../components/loader/GenericLoader';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import WundaButton from '../components/wundamental/WundaButton';
import { analytics } from '../firebase/firebase';
import { AuthContext } from '../providers/AuthProvider';
import { apiRequest } from '../api/api';

const ProfilePage = () => {
  const { currentUser, refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: currentUser.DBuser?.name || '',
      email: currentUser.DBuser?.email || '',
    },
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'info' });
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  const handleBillingInfoSubmit = () => {
    showSuccess('Billing information updated successfully');
  };

  const handleDeleteConfirm = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmDialog = async () => {
    // Add your delete account logic here
    try {
      if (!currentUser.DBuser) {
        throw new Error('Something is wrong with your user account!');
      }
      setLoading(true);
      await apiRequest('DELETE', `user/${currentUser.DBuser._id}`, {
        softDelete: false,
      });
      logEvent(analytics, 'delete_account', { method: 'profile' });
      let countdown = 3;
      const intervalId = setInterval(() => {
        countdown -= 1;
        showSuccess(`Successfully deleted your account! Logging off in ${countdown} seconds...`);
        if (countdown <= 0) {
          clearInterval(intervalId);
          navigate('/logout');
        }
      }, 1000);
    } catch (error) {
      setErrorMessage(`Failed to delete the account: ${error.message}`);
      console.error('Failed to delete the account:', error);
    } finally {
      setLoading(false);
      setOpenDeleteDialog(false);
    }
  };

  const onSubmit = async (data) => {
    const { name, email } = data;
    const payload = { name, email };

    try {
      setLoading(true);
      await apiRequest('PUT', `/user/${currentUser.DBuser._id}`, {
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      logEvent(analytics, 'update_profile', { method: 'profile' });
      showSuccess('Profile updated successfully');
      refreshUser();
    } catch (error) {
      setErrorMessage(`Failed to update the profile: ${error.message}`);
      console.error('Failed to update the profile:', error);
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  return (
    <>
      {loading && <GenericLoader />}
      <Container component="main" sx={{ mt: 2 }}>
        <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Your Profile</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Full Name is required' }}
                    className="fs-mask"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Full Name"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    className="fs-mask"
                    rules={{
                      required: 'Email is required',
                      validate: (value) => validateEmail(value) || 'Please enter a valid email',
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email address"
                        type="email"
                        error={Boolean(errors.email)}
                        helperText="Changing email is currently not available. Please reach out to support for assistance."
                        fullWidth
                        disabled
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <WundaButton type="submit" variant="contained" color="primary">
                      Save
                    </WundaButton>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
        <Box mt={2}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">Billing Information</Typography>
              <BillingInformationForm
                onSuccess={handleBillingInfoSubmit}
                showBackButton={false}
                submitButtonText="Save"
              />
            </CardContent>
          </Card>
        </Box>
        <Box mt={2}>
          <Card variant="outlined">
            <CardContent>
              <Box mb={2}>
                <Typography variant="h6">Your Account</Typography>
              </Box>
              <Box>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteConfirm();
                  }}
                  style={{ color: 'red' }}
                >
                  Delete my account
                </Link>
              </Box>
              <Typography variant="body2">
                Deleting your account will result in loss of all data and this action cannot be undone.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <GenericSnackbar open={snackbar} autoHideDuration={6000} onClose={handleSnackbarClose} />
      <PromptDialog
        open={openDeleteDialog}
        title="Confirm Account Deletion"
        message="Are you sure you want to delete your account and all the data?"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmDialog}
        confirmWithName={currentUser.DBuser?.name || currentUser.displayName}
      />
    </>
  );
};

export default ProfilePage;
