import { useState } from 'react';
import { Stack, TextField, Alert, Box } from '@mui/material';
import PropTypes from 'prop-types';
import WundaButton from '../../components/wundamental/WundaButton';

export default function LoginForm({ onLogin, error }) {
  const [email, setEmail] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault(); // prevent default form submission
    console.log('Login attempt with email:', email); // Debugging log
    if (email) {
      try {
        console.log('Calling onLogin with email:', email); // Debugging log
        await onLogin(email);
      } catch (error) {
        console.log('Error in handleLogin:', error); // Debugging log
        // The error will be handled by the parent component
      }
    }
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleLogin}>
        <Stack spacing={3} sx={{ mb: 3, mt: 3 }}>
          <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Stack>

        <Box display="flex" justifyContent="center">
          <WundaButton size="large" type="submit" variant="contained" sx={{ width: 200 }}>
            Login
          </WundaButton>
        </Box>
      </form>
    </>
  );
}

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  error: PropTypes.string, // Add the error prop definition
};
