import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { Typography, Container, Grid, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConsentForm from '../sections/@dashboard/consent/ConsentForm';
import ConsentContractCard from '../sections/@dashboard/consent/ConsentContractCard';
import ConsentGuideSection from '../sections/@dashboard/consent/ConsentGuideSection';
import ConsentSignForm from '../sections/@dashboard/consent/ConsentSignForm';
import ConsentList from '../sections/@dashboard/consent/ConsentList';
import ErrorMessage from '../components/alert/ErrorMessage';
import WundaModal from '../components/wundamental/WundaModal';
import WundaButton from '../components/wundamental/WundaButton';
import GenericLoader from '../components/loader/GenericLoader';
import { apiRequest } from '../api/api';

const ConsentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { consentId, consentUid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [consentData, setConsentData] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    resetStates();
  }, [location]);

  const fetchConsentData = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const url = consentUid ? `/consent/uid/${consentUid}` : `/consent/${consentId}`;
      const consentData = await apiRequest('GET', url);
      setConsentData(consentData);
    } catch (error) {
      if (error.response?.status === 404) {
        navigate('/404');
      } else {
        setErrorMessage(`Failed to fetch consent. ${error.response?.data?.message || error}`);
      }
    } finally {
      setIsLoading(false);
    }
  }, [consentId, consentUid, navigate]);

  const handleNewConsentClick = () => {
    setShowForm(true);
  };

  const resetStates = () => {
    setIsLoading(false);
    setErrorMessage(null);
    setConsentData(null);
    setShowForm(false);
  };

  useEffect(() => {
    if (consentId || consentUid) {
      fetchConsentData();
    }
  }, [fetchConsentData, consentId, consentUid]);

  if (isLoading) {
    return <GenericLoader />;
  }

  return (
    <>
      <Helmet>
        <title>Consent | Wundamental</title>
      </Helmet>
      <Container maxWidth="xl">
        {consentData && consentId && (
          <WundaButton
            startIcon={<ArrowBackIcon />}
            sx={{ m: 1 }}
            onClick={() => {
              navigate('/home/consent');
              resetStates();
            }}
          >
            Consents
          </WundaButton>
        )}
        <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
        <Typography variant="h4" sx={{ textAlign: 'center' }} mb={3}>
          Consent for Recording and AI Analysis
        </Typography>
        <Grid container spacing={1}>
          {consentData ? (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <ConsentContractCard consentData={consentData} isSigning={!!consentUid} sx={{ mt: 2 }} />
              </Grid>
              {(consentUid || (consentData && consentData.signed)) && (
                <Grid item xs={12} md={12} lg={12}>
                  <Box display="flex" justifyContent="center">
                    <Box maxWidth="50%" minWidth="300px">
                      <ConsentSignForm consentData={consentData} />
                    </Box>
                  </Box>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12} md={6} lg={8}>
                <ConsentGuideSection onClick={handleNewConsentClick} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <ConsentList title="Consents" subheader="List of your consents" />
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      <WundaModal open={showForm} onClose={() => setShowForm(false)} showCloseButton>
        <ConsentForm showForm={showForm} setShowForm={setShowForm} />
      </WundaModal>
    </>
  );
};

export default ConsentPage;
