import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { LinearProgress, Box, Typography } from '@mui/material';

const WundaProgress = ({ value, label = null }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: '100%' }}>
      {label && (
        <Typography id="transition-modal-title" variant="h6" component="h2">
          {label}
        </Typography>
      )}
      <Box sx={{ height: 10, borderRadius: 5, overflow: 'hidden', mt: 1, mb: 2 }}>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: '100%',
            borderRadius: 5,
            '& .MuiLinearProgress-bar': {
              bgcolor: theme.palette.primary.main,
            },
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

WundaProgress.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
};

export default WundaProgress;
