import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';

export default function LogoutPage() {
  const navigate = useNavigate();
  const { setCurrentUser, forceLogout } = useContext(AuthContext);

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await forceLogout(); // Sign out the user
        setCurrentUser(null); // Clear the current user from the context
        navigate('/login', { replace: true });
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    handleLogout();
  }, [navigate, forceLogout, setCurrentUser]);

  return <div>Logging out...</div>;
}
