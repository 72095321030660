import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, Link, FormControl, FormHelperText } from '@mui/material';

const TERMS_OF_SERVICE_URL = 'https://wundamental.ai/terms-of-service';
const PRIVACY_POLICY_URL = 'https://wundamental.ai/privacy-policy';
const AGREEMENT_GUIDELINES_URL = 'https://wundamental.ai/blog/agreement-guidelines/';

export default function TermsOfServiceCheckbox({ onAcceptanceChange, onConsentChange, accepted, consented, error }) {
  const handleAcceptanceChange = (event) => {
    onAcceptanceChange(event.target.checked);
  };

  const handleConsentChange = (event) => {
    onConsentChange(event.target.checked);
  };

  return (
    <FormControl required error={error}>
      <FormControlLabel
        control={
          <Checkbox
            checked={accepted}
            defaultValue={false}
            onChange={handleAcceptanceChange}
            name="acceptedTerms"
            color="primary"
          />
        }
        label={
          <>
            I accept the{' '}
            <Link href={TERMS_OF_SERVICE_URL} target="_blank" rel="noopener">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link href={PRIVACY_POLICY_URL} target="_blank" rel="noopener">
              Privacy Policy
            </Link>
          </>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={consented}
            defaultValue={false}
            onChange={handleConsentChange}
            name="consented"
            color="primary"
          />
        }
        label={
          <>
            I consent to analyse only those sessions to which I have consent from all participants.{' '}
            <Link href={AGREEMENT_GUIDELINES_URL} target="_blank" rel="noopener">
              Read more.
            </Link>
          </>
        }
      />
      {error && <FormHelperText>Please accept the terms and privacy policy</FormHelperText>}
    </FormControl>
  );
}

TermsOfServiceCheckbox.propTypes = {
  onAcceptanceChange: PropTypes.func.isRequired,
  onConsentChange: PropTypes.func.isRequired,
  accepted: PropTypes.bool.isRequired,
  consented: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};
