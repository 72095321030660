import React, { useEffect, useState, useContext } from 'react';
import { Alert } from '@mui/material';
import { AuthContext } from '../../providers/AuthProvider';
import { apiRequest } from '../../api/api'; // import your apiRequest function
import ErrorMessage from '../alert/ErrorMessage';

const StripePricingTable = () => {
  console.log('StripePricingTable rendered');
  const { currentUser } = useContext(AuthContext); // Get currentUser from context
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);

  useEffect(() => {
    const createSession = async () => {
      try {
        const response = await apiRequest('POST', '/payment/customer/session');
        console.log(response.session); // Log the session
        setSession(response, () => {
          setLoading(false);
        });

        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      } catch (error) {
        // setErrorMessage(error.response?.data?.message || error.message);
      } finally {
        setLoading(false);
      }

      return null; // Add this line
    };

    setLoading(true);
    createSession();
    // Remove setLoading(false) from here
  }, []);

  if (!currentUser.DBuser?._id) {
    return <Alert severity="error">User ID not found. Please log in again.</Alert>;
  }

  if (loading) {
    return <div>Loading...</div>; // Show loading state while session data is being fetched
  }

  if (errorMessage) {
    return <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />;
  }

  const stripePricingTableProps = {
    'pricing-table-id': process.env.REACT_APP_PRICING_TABLE_ID,
    'publishable-key': process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    'client-reference-id': currentUser.DBuser._id, // Set client reference ID to currentUser.DBuser._id
  };

  if (session?.client_secret) {
    stripePricingTableProps['customer-session-client-secret'] = session.client_secret;
  } else {
    stripePricingTableProps['customer-email'] = currentUser.DBuser.email;
  }

  return React.createElement('stripe-pricing-table', stripePricingTableProps);
};

export default StripePricingTable;
