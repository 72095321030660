import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@mui/material';
import WundaButton from './WundaButton';

const DownloadInsightsFormatDialog = ({ open, isLoading, onCancel, onConfirm }) => {
  const [format, setFormat] = React.useState('docx'); // Default format

  const handleFormatChange = (event) => {
    setFormat(event.target.value);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleDownload = () => {
    if (onConfirm) {
      onConfirm(format);
    }
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Select Download Format</DialogTitle>
      <DialogContent>
        <DialogContentText>Choose the format for your download:</DialogContentText>
        <RadioGroup aria-label="download-format" value={format} onChange={handleFormatChange}>
          <FormControlLabel value="docx" control={<Radio />} label="DOCX" />
          <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <WundaButton variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </WundaButton>
          </Grid>
          <Grid item>
            <WundaButton onClick={handleDownload} variant="contained" color="primary" disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : 'Download'}
            </WundaButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

DownloadInsightsFormatDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DownloadInsightsFormatDialog;
