import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Grid, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import WundaButton from '../../../components/wundamental/WundaButton';

const ConsentGuideSection = ({ onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState('panel1');

  const handleCardClick = (panel) => () => {
    setExpanded(expanded === panel ? null : panel);
  };

  return (
    <Box sx={{ width: '100%', mx: 'auto' }}>
      <Card sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {isMobile ? (
            <>
              <Grid item xs={12}>
                <WundaButton onClick={handleCardClick('panel1')} variant="outlined" fullWidth>
                  💡 General Tips 💡
                </WundaButton>

                <WundaButton onClick={handleCardClick('panel2')} variant="outlined" fullWidth>
                  For Facilitators with New Participants
                </WundaButton>

                <WundaButton onClick={handleCardClick('panel3')} variant="outlined" fullWidth>
                  For Facilitators with Existing Participants
                </WundaButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={4}>
                <Card
                  onClick={handleCardClick('panel1')}
                  sx={{
                    flex: 1,
                    mt: 1,
                    borderRadius: 2,
                    height: 100,
                    width: '100%',
                    cursor: 'pointer',
                    backgroundColor:
                      expanded === 'panel1' ? theme.palette.secondary.main : theme.palette.background.paper,
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <CardContent>
                      <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                        💡 General Tips
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Card
                  onClick={handleCardClick('panel2')}
                  sx={{
                    flex: 1,
                    mt: 1,
                    borderRadius: 2,
                    height: 100,
                    cursor: 'pointer',
                    width: '100%',
                    backgroundColor:
                      expanded === 'panel2' ? theme.palette.secondary.main : theme.palette.background.paper,
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <CardContent>
                      <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                        For Facilitators with New Participants
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Card
                  onClick={handleCardClick('panel3')}
                  sx={{
                    flex: 1,
                    mt: 1,
                    borderRadius: 2,
                    height: 100,
                    width: '100%',
                    cursor: 'pointer',
                    backgroundColor:
                      expanded === 'panel3' ? theme.palette.secondary.main : theme.palette.background.paper,
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <CardContent>
                      <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                        For Facilitators with Existing Participants
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
        <Box sx={{ p: 0 }}>
          {expanded === 'panel1' && (
            <CardContent>
              {' '}
              <Typography variant="body1" mb={2}>
                <b>Emphasize Confidentiality and Security:</b> Reassure participants about the stringent measures you and
                Wundamental.ai take to protect their data.
              </Typography>
              <Typography variant="body1" mb={2}>
                <b>Highlight the Value:</b> Clearly articulate how AI analysis will add value to their experience.
              </Typography>
              <Typography variant="body1" mb={2}>
                <b>Offer Examples:</b> Where possible, provide anonymized examples of how AI analysis has led to
                breakthroughs or significant progress for other participants.
              </Typography>
              <Typography variant="body1" mb={2}>
                <b>Maintain an Open Dialogue:</b> Encourage participants to share their feelings and thoughts about the
                use of AI in their sessions, fostering an environment of trust and openness.
              </Typography>
              <Typography variant="body1" mb={2}>
                These adaptations help ensure your communication strategy is inclusive and applicable to various types
                of interactions, respecting and enhancing the facilitator-participant relationship.
              </Typography>
            </CardContent>
          )}
          {expanded === 'panel2' && (
            <CardContent>
              {' '}
              <Typography variant="body1" mb={2}>
                <b>Incorporating AI Analysis into the Session Agreement:</b> When drafting the initial agreement or
                contract, include a section dedicated to the use of AI analysis. Describe its purpose, benefits, and
                application to enhance the session. Detail the procedures for recording, analyzing, and storing
                sessions, emphasizing the security measures and confidentiality protocols in place to protect privacy.
              </Typography>
              <Typography variant="body1" mb={2}>
                <b>Transparent Onboarding Process:</b> During the onboarding session, openly discuss the role of AI
                analysis. Explain how it contributes to a more personalized and effective experience by providing deeper
                insights into challenges and progress. Encourage questions and address any concerns.
              </Typography>
            </CardContent>
          )}
          {expanded === 'panel3' && (
            <CardContent>
              {' '}
              <Typography variant="body1" mb={2}>
                <b>Introduction of Enhanced Session Experience:</b> Send a personal message or introduce the concept of
                incorporating AI analysis at the beginning of your next session. Explain how this will deepen insights
                and help identify patterns that might not be evident in real-time conversations, increasing the value
                gained from the session.
              </Typography>
              <Typography variant="body1" mb={2}>
                <b>Seeking Informed Consent:</b> Clearly explain the process, including how audio recordings will be
                used, stored, and protected. Assure them of the confidentiality and security measures in place to
                safeguard their information. Provide a consent form that outlines the use of AI analysis, what it
                entails, and how their data will be handled. Make it clear that their participation is voluntary and
                that they have the right to opt out at any time without affecting their future experience with you.
              </Typography>
            </CardContent>
          )}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <WundaButton variant="contained" onClick={onClick}>
            Ask for Consent
          </WundaButton>
        </Box>
      </Card>
    </Box>
  );
};

ConsentGuideSection.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ConsentGuideSection;
