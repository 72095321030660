import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText, Divider, ListItemButton, ListItemIcon, Collapse, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from '../../providers/AuthProvider';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const { isFeatureEnabled } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const filteredData = data.filter((item) => {
    if (item.feature) {
      return isFeatureEnabled(item.feature);
    }
    return true;
  });

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {filteredData.map((item, index) => (
          <React.Fragment key={item.title}>
            {item.title === 'Advanced features' ? (
              <>
                <ListItemButton onClick={handleClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={<Typography variant="body2">{item.title}</Typography>} />
                  {open ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                <Collapse in={item.title === 'Advanced features' ? true : open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((child, childIndex) => (
                      <NavItem key={`${index}-${childIndex}`} item={child} isChild />
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <NavItem item={item} />
            )}
            {item.dividerAfter && <Divider sx={{ mb: 2 }} />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
  isChild: PropTypes.bool,
};

function NavItem({ item, isChild }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
        pl: isChild ? 4 : 0, // Add left padding if it's a child item
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={isChild ? <Typography variant="body2">{title}</Typography> : title} />

      {info && info}
    </StyledNavItem>
  );
}
