import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  TextField,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import WundaButton from './WundaButton';

const WundaShareToEmailDialog = ({ open, isLoading, onCancel, onConfirm, dialogProps }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const StyledDialogTitle = styled(DialogTitle)({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: 'bold',
    padding: '20px',
  });

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setIsEmailValid(validateEmail(emailInput)); // Validate email on change
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleShare = () => {
    if (onConfirm) {
      onConfirm(email, subject, body);
      setEmail('');
    }
  };

  const handleSendClick = () => {
    if (isEmailValid && email) {
      setConfirmOpen(true);
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmYes = () => {
    handleShare();
    setConfirmOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth={isDesktop ? 'md' : 'xs'}>
      <StyledDialogTitle>{dialogProps.title}</StyledDialogTitle>
      <DialogContent style={{ minWidth: isDesktop ? '800px' : '300px', overflowY: 'initial' }}>
        {isLoading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '200px', padding: '20px' }}
          >
            <CircularProgress />
            <Typography variant="h6" style={{ marginTop: '20px' }}>
              Sending...
            </Typography>
          </Grid>
        ) : (
          <>
            <DialogContentText sx={{ mt: 2 }}>{dialogProps.bodyText}</DialogContentText>
            <TextField
              autoFocus
              aria-label="share-email"
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              sx={{ marginTop: 2 }}
              error={!isEmailValid}
              helperText={!isEmailValid && 'Please enter a valid email'}
              className="fs-mask"
            />
            {dialogProps.subjectField && (
              <TextField
                margin="dense"
                id="subject"
                label="Subject"
                placeholder={dialogProps.subjectFieldPlaceholder}
                type="text"
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                sx={{ marginTop: 2 }}
                className="fs-mask"
              />
            )}
            {dialogProps.bodyField && (
              <TextField
                margin="dense"
                id="body"
                label="Your message"
                placeholder={dialogProps.bodyFieldPlaceholder}
                type="text"
                fullWidth
                multiline
                rows={4}
                value={body}
                onChange={(e) => setBody(e.target.value)}
                sx={{ marginTop: 2 }}
                className="fs-mask"
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <WundaButton variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </WundaButton>
          </Grid>
          <Grid item>
            <WundaButton
              onClick={handleSendClick}
              variant="contained"
              color="primary"
              disabled={isLoading || !isEmailValid || !email}
            >
              Send Email
            </WundaButton>
          </Grid>
        </Grid>
      </DialogActions>

      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <Alert severity="warning">
          <AlertTitle>Confirmation</AlertTitle>
          <Typography className="fs-mask">
            {(dialogProps.confirmPromptText && dialogProps.confirmPromptText.replace('{email}', email)) ||
              `Are you sure you want to share to ${email}?`}
          </Typography>
        </Alert>
        <DialogActions>
          <WundaButton onClick={handleConfirmClose} color="primary" variant="outlined">
            No
          </WundaButton>
          <WundaButton onClick={handleConfirmYes} color="primary" variant="outlined" autoFocus>
            Yes
          </WundaButton>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

WundaShareToEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  dialogProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    confirmPromptText: PropTypes.string,
    subjectField: PropTypes.bool,
    bodyField: PropTypes.bool,
    subjectFieldPlaceholder: PropTypes.string,
    bodyFieldPlaceholder: PropTypes.string,
  }).isRequired,
};

export default WundaShareToEmailDialog;
