import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  TextField,
  Grid,
  Alert,
  Box,
  Chip,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { apiRequest } from '../../../api/api';
import FileUpload from '../../../components/file-upload/FileUpload';
import GenericLoader from '../../../components/loader/GenericLoader';
import WundaButton from '../../../components/wundamental/WundaButton';

const ExistingTranscriptUploadForm = ({ currentUser, onCancel, onSuccess }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      sessionDate: null,
      transcriptFile: null,
    },
  });

  const theme = useTheme();
  const name = watch('name');

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const handleTranscriptSubmit = async (data) => {
    const { name, sessionDate, sessionDuration, language, transcriptFile, tags } = data;

    // Create form data
    const formData = new FormData();
    formData.append('name', name);
    formData.append('format', 'docx');
    if (sessionDate) {
      const formattedSessionDate = new Date(Date.UTC(sessionDate.$y, sessionDate.$M, sessionDate.$D));
      formData.append('sessionDate', formattedSessionDate.toISOString());
    }

    formData.append('sessionDuration', Number(sessionDuration));
    formData.append('language', language);
    formData.append('transcriptFile', transcriptFile);
    formData.append('originalFileName', transcriptFile.name);
    formData.append('type', 'docx');
    formData.append('userId', currentUser.DBuser._id);
    formData.append('tags', JSON.stringify(tags));

    try {
      setLoading(true);
      // Send POST request using apiRequest
      await apiRequest('POST', '/transcript/file/upload', {
        data: formData,
      });

      // Show success message
      setSuccessMessage('Transcript uploaded successfully');

      // Reset form fields
      reset();

      // Call the onSuccess callback with the success message
      if (onSuccess) {
        onSuccess(successMessage);
      }

      // Call the onCancel callback with the success message
      if (onCancel) {
        onCancel();
      }
    } catch (error) {
      // Handle errors
      console.error('Upload failed:', error);

      // Show error message
      setErrorMessage(`Error uploading transcript: ${error.response?.data?.message || error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleTranscriptSubmit)}>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: theme.palette.background.paper,
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <Grid item xs={12} sx={{ width: '50%' }}>
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Please enter a name' }}
            render={({ field }) => (
              <TextField {...field} label="Name" fullWidth error={!!errors.name} helperText={errors.name?.message} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              name="sessionDate"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Session Date"
                  value={field.value ? dayjs(field.value) : null} // Set a default value of null for uncontrolled state
                  onChange={(newValue) => field.onChange(newValue)} // Call field.onChange to update the value in the controlled state
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="sessionDuration"
            defaultValue="60"
            rules={{ required: 'Please select a session duration' }}
            render={({ field }) => (
              <FormControl component="fieldset">
                <FormLabel component="legend">How long was this session? (minutes)</FormLabel>
                <RadioGroup row {...field}>
                  <FormControlLabel value="15" control={<Radio />} label="15" />
                  <FormControlLabel value="30" control={<Radio />} label="30" />
                  <FormControlLabel value="60" control={<Radio />} label="60" />
                  <FormControlLabel value="90" control={<Radio />} label="90" />
                </RadioGroup>
                {errors.sessionDuration && <FormHelperText>{errors.sessionDuration.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="language"
            defaultValue="en"
            rules={{ required: 'Please select a language' }}
            render={({ field }) => (
              <FormControl component="fieldset">
                <FormLabel component="legend">What is the transcription language?</FormLabel>
                <RadioGroup row {...field}>
                  <FormControlLabel value="en" control={<Radio />} label="English" />
                  <FormControlLabel value="et" control={<Radio />} label="Estonian" />
                </RadioGroup>
                {errors.language && <FormHelperText>{errors.language.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Tags"
            variant="outlined"
            fullWidth
            placeholder="Enter a tag and press Enter or any non-alphanumeric key"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onKeyUp={(event) => {
              // Use regex to remove any non-alphanumeric characters
              const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '').trim();

              // This regex checks if the key pressed is non-alphanumeric or if it's the Enter key
              const isDelimiter = /[^a-zA-Z0-9]/.test(event.key) || event.key === 'Enter';

              if (isDelimiter && sanitizedValue !== '') {
                // Only add the tag if it doesn't already exist in the array
                if (!tags.includes(sanitizedValue)) {
                  const newTags = [...tags, sanitizedValue];
                  setTags(newTags);
                  setValue('tags', newTags); // update the form value too
                }
                // Clear the text field regardless of whether it's a duplicate or not
                event.target.value = '';
              }
            }}
          />
          <Box mt={2}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => {
                  const newTags = tags.filter((t) => t !== tag);
                  setTags(newTags);
                  setValue('tags', newTags); // also update the form value
                }}
                style={{ marginRight: '5px', marginBottom: '5px' }}
              />
            ))}
          </Box>
          <Controller name="tags" control={control} render={() => null} defaultValue={[]} />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="transcriptFile"
            rules={{
              required: 'Please select a transcript file',
              validate: (value) =>
                value &&
                ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'].includes(
                  value.type
                )
                  ? true
                  : 'Invalid file type. Please select a DOCX file.',
            }}
            render={({ field }) => (
              <FileUpload
                onDrop={(acceptedFiles) => {
                  const file = acceptedFiles[0];
                  const nameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');

                  // Only set the name of the transcript if the "name" field is currently empty
                  if (!name) {
                    setValue('name', nameWithoutExtension);
                  }

                  field.onChange(file);
                }}
                acceptedTypes={{
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                  'text/plain': ['.txt'],
                }}
              />
            )}
          />

          {errors.transcriptFile && <Alert severity="error">{errors.transcriptFile.message}</Alert>}
        </Grid>

        <Grid item xs={12}>
          <WundaButton variant="outlined" color="primary" onClick={onCancel} sx={{ mr: 2 }}>
            Cancel
          </WundaButton>
          <WundaButton type="submit" variant="contained" color="primary" disabled={loading}>
            Submit
          </WundaButton>
        </Grid>
      </Grid>
      <Box mt={2}>
        {loading && <GenericLoader />}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Box>
    </form>
  );
};

ExistingTranscriptUploadForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default ExistingTranscriptUploadForm;
