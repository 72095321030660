import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Container, Typography, Box, Alert, Tooltip, IconButton, Snackbar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useTheme } from '@mui/material/styles';
import { AuthContext } from '../providers/AuthProvider';
import StripePricingTable from '../components/wundamental/StripePricingTable';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import Iconify from '../components/iconify';
import WundaButton from '../components/wundamental/WundaButton';
import { apiRequest } from '../api/api';

const isTestMode = process.env.REACT_APP_STRIPE_LIVE_MODE_ENABLED !== 'true';

const SubscribePage = () => {
  const { currentUser, refreshUser } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:1000px)');
  const { stripePendingPromoCode, pendingDiscountTotal } = currentUser.DBuser?.stripeDiscounts;
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [hasUserRefreshed, setHasUserRefreshed] = useState(false);

  const navigateToBilling = async () => {
    try {
      const payload = {
        userId: currentUser.DBuser._id,
        isTestMode: true,
      };

      const response = await apiRequest('POST', '/payment/create-portal-session', { data: payload });
      // Redirect the user to the URL returned by the API
      window.location = response.url;
    } catch (error) {
      console.log(error);
    }
  };

  // Navigate to billing if the user's subscription is active
  if (currentUser?.DBuser?.subscription?.isActive) {
    navigateToBilling();
  }

  // Refresh user data on first load
  if (!hasUserRefreshed) {
    refreshUser();
    setHasUserRefreshed(true);
  }

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  return (
    <>
      <Helmet>
        <title> Plans & Pricing | Wundamental </title>
      </Helmet>

      {isTestMode && (
        <Snackbar open={isTestMode} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="warning" icon={<Iconify icon="noto-v1:warning" />}>
            TEST MODE! Payments are not real!
          </Alert>
        </Snackbar>
      )}

      <Container
        component="main"
        maxWidth="false"
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box
          component="main"
          sx={{
            width: '100%',
            maxWidth: '80%',
            minWidth: '60%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Plans & Pricing
          </Typography>
          <Card
            sx={{
              width: '100%',
              mt: 1,
              p: 0,
              boxShadow: `0px 2px 10px ${theme.palette.background.GreyBG02}`,
              minWidth: isSmallScreen ? '100%' : 'max-content',
              maxWidth: '100%',
              height: '100%',
            }}
          >
            <StripePricingTable />
            <Typography variant="body2" align="center" gutterBottom sx={{ mt: 2 }}>
              * VAT charges may apply as per the billing address.
            </Typography>
            <Box
              sx={{
                mt: 8,
                mb: 2,
                width: '40%',
                mx: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ mb: 2 }} align="center" gutterBottom>
                <strong>Team of coaches</strong> looking to have a centrally managed business account and gain{' '}
                <span style={{ color: theme.palette.primary.light }}>
                  <strong>group AI Insights</strong>
                </span>{' '}
                to service business clients
              </Typography>
              <WundaButton
                endIcon={<Iconify icon="mdi:arrow-right" />}
                variant="outlined"
                color="primary"
                onClick={() => window.open('https://form.typeform.com/to/QIn8b3su', '_blank')}
              >
                Contact us
              </WundaButton>
            </Box>
          </Card>
          {stripePendingPromoCode && pendingDiscountTotal > 0 && (
            <Card
              sx={{
                width: '100%',
                mt: 1,
                p: 2,
                boxShadow: `0px 2px 10px ${theme.palette.background.GreyBG02}`,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Iconify
                  icon="mdi:sale"
                  width={40}
                  height={40}
                  style={{ fontSize: '2em', color: theme.palette.primary.main }}
                />
                <Typography variant="h6" component="h2" gutterBottom>
                  Good news!
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                  You are eligible for a discount of <strong>{pendingDiscountTotal / 100} EUR</strong> with the
                  promotion code below!
                </Typography>
                <Box
                  sx={{
                    mt: 1,
                    height: '4em',
                    display: 'inline-block',
                    border: '1px dotted',
                    color: theme.palette.secondary.contrastText,
                    borderColor: theme.palette.primary.main,
                    borderRadius: '5px',
                    p: 1,
                    backgroundColor: theme.palette.secondary.main,
                    position: 'relative',
                  }}
                >
                  <code style={{ color: theme.palette.secondary.contrastText, fontWeight: 'bold' }}>
                    {stripePendingPromoCode}
                  </code>
                  <Tooltip title="Copy to clipboard">
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        fontSize: '1rem',
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(stripePendingPromoCode);
                        showSuccess('Promo code copied');
                      }}
                    >
                      <FileCopyIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Card>
          )}
        </Box>
      </Container>

      <GenericSnackbar open={snackbar} autoHideDuration={6000} onClose={handleSnackbarClose} />
    </>
  );
};

export default SubscribePage;
