import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography, Container, Stack, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Iconify from '../components/iconify'; // Replace with the actual path

const platforms = [
  {
    title: 'Google Meet',
    icon: <Iconify icon={'logos:google-meet'} />,
    instructions: `
        Start or Join a Meeting: Log in to your Google account and start a new meeting or join an existing one.
        Check Permissions: Ensure you are the meeting organizer or are in an organization that allows meeting recordings.
        Begin Recording: Click the three dots (more options) at the bottom right of the meeting screen, then select "Record meeting". If you don’t see this option, recording isn't permitted in your organization.
        Inform Participants: Once you start recording, all participants will be notified. Make sure you have their consent.
        Stop Recording: Click the three dots again and select "Stop recording". The recording will automatically be saved to the organizer’s Google Drive in the 'Meet Recordings' folder.
        Access and Share Your Recording: You will receive an email with the recording link once the meeting ends. Share this with your Wundamental platform for analysis.`,
  },
  {
    title: 'Microsoft Teams',
    icon: <Iconify icon={'logos:microsoft-teams'} />,
    instructions: `
        Start or Join a Meeting: Open Teams, start a new meeting or join an existing one.
        Permission to Record: Ensure you have the necessary permissions to record. Only the meeting organizer or a presenter can start a recording.
        Begin Recording: Go to the meeting controls and select "More options" (three dots), then choose "Start recording".
        Notify Participants: Participants will be notified when the recording starts or stops.
        End Recording: The recording will stop automatically when the meeting ends or manually select "More options" > "Stop recording".
        Access Your Recording: The recording will be stored in Microsoft Stream. You'll receive a link to the recording in the meeting chat.`,
  },
  {
    title: 'Zoom',
    icon: <Iconify icon={'logos:zoom'} />,
    instructions: `
        Start or Join a Meeting: Launch Zoom, start a new meeting or join one.
        Permission to Record: Ensure you are the host or have been granted permission to record by the host.
        Start Recording: Click the "Record" button on the toolbar at the bottom of the meeting window. For participants, this option is only available if allowed by the host.
        Inform Participants: Zoom will notify all participants that recording has started. Ensure you have their consent.
        Stop or Pause Recording: Use the "Stop" or "Pause" button on the toolbar to control the recording.
        Access and Share Your Recording: If you’re recording to your computer, the file will be saved to your Zoom folder once the meeting is over. If recording to the cloud, you will receive an email with the recording link.`,
  },
  {
    title: 'Offline meetings',
    icon: <Iconify icon={'oui:offline'} />,
    instructions: `
        You can use any voice recording app on your phone to record your conversation.
        There are many apps that are free like <a target="_new" href="https://apps.apple.com/us/app/voice-memos/id1069512134">Apple Voice Memos</a> for iOS, or <a target="_new" href="https://play.google.com/store/apps/details?id=com.andrwq.recorder&hl=en&gl=US&pli=1">Smart Voice Recorder</a> for Android.
        Once the conversation is over, you can upload the recording here.`,
  },
];

function HowToRecord() {
  return (
    <>
      <Helmet>
        <title> How to record meetings? | Wundamental </title>
      </Helmet>
      <Container maxWidth={false}>
        <Stack direction="column" alignItems="center" justifyContent="space-between" my={2}>
          <Typography variant="h4" gutterBottom>
            How to Record Your Meeting?
          </Typography>
        </Stack>
        <Box display="flex" justifyContent="center">
          <Card sx={{ marginTop: 2, maxWidth: '80%' }}>
            {platforms.map((platform, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <Box mr={2}>{platform.icon}</Box>
                  <Typography>{platform.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Box ml={2}>
                      <ul>
                        {platform.instructions.split('\n').map((instruction, i) => {
                          const trimmedInstruction = instruction.trim();
                          return (
                            trimmedInstruction && (
                              // eslint-disable-next-line react/no-danger
                              <li key={i} dangerouslySetInnerHTML={{ __html: trimmedInstruction }} />
                            )
                          );
                        })}
                      </ul>
                    </Box>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Card>
        </Box>
      </Container>
    </>
  );
}

export default HowToRecord;
