// Import necessary libraries
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography, Modal, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FileWithMetadata } from '../utils/customTypes';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import WundaVoiceRecorder from '../components/wundamental/WundaVoiceRecorder';
import SessionUploadForm from '../sections/@dashboard/session/SessionUploadForm';
import WundaButton from '../components/wundamental/WundaButton';

export default function SessionsPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const snackbarRef = useRef(null);

  const [showSessionUploadModal, setShowSessionUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(FileWithMetadata());

  const handleSessionUploadModalClose = () => {
    setShowSessionUploadModal(false);
    setUploadedFile(null);
  };

  const handleSessionUploadSuccess = () => {
    // Refresh the table data
    setUploadedFile(null);

    let countdown = 10;
    const intervalId = setInterval(() => {
      countdown -= 1;
      if (countdown <= 0) {
        clearInterval(intervalId);
        navigate('/home/sessions');
      } else {
        snackbarRef.current.showSnackbar({
          message: `Successfully uploaded session. Redirecting to sessions page, where you can track the progress, in ${countdown} seconds...`,
          severity: 'info',
          action: (
            <WundaButton
              color="secondary"
              size="small"
              onClick={() => {
                clearInterval(intervalId);
                snackbarRef.current.closeSnackbar();
              }}
            >
              STAY HERE
            </WundaButton>
          ),
          autoHideDuration: 0,
        });
      }
    }, 1000);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '50%', // If screen size is small, width is 100%, else it's 50%.
    bgcolor: 'background.paper',
    border: '2px solid #365a47',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };

  // Define the RecorderPage component
  return (
    <>
      <Helmet>
        <title> Voice Recorder | Wundamental </title>
      </Helmet>

      <Container maxWidth={false}>
        <Stack direction="column" alignItems="center" justifyContent="space-between" my={2}>
          <Typography variant="h4" gutterBottom>
            Voice Recorder
          </Typography>
          <Typography variant="caption" gutterBottom style={{ fontWeight: 'bold' }}>
            Experimental
          </Typography>
        </Stack>
        <WundaVoiceRecorder onUpload={() => setShowSessionUploadModal(true)} uploadedFile={setUploadedFile} />

        <Modal open={showSessionUploadModal} onClose={() => setShowSessionUploadModal(false)}>
          <Box sx={modalStyle}>
            <SessionUploadForm
              sessionFile={uploadedFile}
              onSuccess={handleSessionUploadSuccess}
              onCancel={handleSessionUploadModalClose}
            />
          </Box>
        </Modal>
      </Container>

      <GenericSnackbar ref={snackbarRef} />
    </>
  );
}
