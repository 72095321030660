import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  TextField,
  Grid,
  Alert,
  Box,
  Chip,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { apiRequest } from '../../../api/api';
import FileUpload from '../../../components/file-upload/FileUpload';
import GenericLoader from '../../../components/loader/GenericLoader';
import WundaButton from '../../../components/wundamental/WundaButton';

const RecordingUploadForm = ({ currentUser, onCancel, onSuccess }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      audioFile: null,
    },
  });

  const theme = useTheme();

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const handleUploadSubmit = async (data) => {
    const { language, audioFile, tags, duration = null } = data;

    // Create form data
    const formData = new FormData();
    formData.append('language', language);
    formData.append('audioFile', audioFile);
    formData.append('userId', currentUser.DBuser._id);
    formData.append('tags', JSON.stringify(tags));
    if (duration) {
      formData.append('duration', duration);
    }

    try {
      setLoading(true);
      // Send POST request using apiRequest
      await apiRequest('POST', '/audio/file/upload', {
        data: formData,
      });

      // Show success message
      setSuccessMessage('Audio file uploaded successfully');

      // Reset form fields
      reset();

      // Call the onSuccess callback with the success message
      if (onSuccess) {
        onSuccess(successMessage);
      }

      // Call the onCancel callback with the success message
      if (onCancel) {
        onCancel();
      }
    } catch (error) {
      // Handle errors
      console.error('Upload failed:', error.message);

      // Show error message
      setErrorMessage(`Error uploading audio file: ${error.response?.data?.message || error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUploadSubmit)}>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: theme.palette.background.paper,
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <Typography variant="subtitle1" component="h2" gutterBottom>
          This media file can be .aac, .flac, .m4a, .mp3, .mp4, .mpeg, .ogg, .wav
        </Typography>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="audioFile"
            rules={{
              required: 'Please select an audio file',
              validate: (value) =>
                value &&
                [
                  'audio/aac',
                  'audio/flac',
                  'audio/x-m4a',
                  'audio/mpeg',
                  'audio/mp4',
                  'audio/ogg',
                  'audio/wav',
                  'video/webm',
                ].includes(value.type)
                  ? true
                  : 'Invalid file type. Please select an AAC, FLAC, M4A, MP3, MP4, MPEG, OGG, WEBM, or WAV file.',
            }}
            render={({ field }) => (
              <FileUpload
                onDrop={(acceptedFiles) => {
                  const file = acceptedFiles[0];
                  field.onChange(file);
                }}
                acceptedTypes={{
                  'audio/aac': ['.aac'],
                  'audio/flac': ['.flac'],
                  'audio/x-m4a': ['.m4a'],
                  'audio/mpeg': ['.mp3', '.mpeg'],
                  'audio/mp4': ['.mp4'],
                  'audio/ogg': ['.ogg'],
                  'audio/wav': ['.wav'],
                  'video/webm': ['.webm'],
                }}
              />
            )}
          />

          {errors.audioFile && <Alert severity="error">{errors.audioFile.message}</Alert>}
        </Grid>

        {/* <Grid item xs={12} sx={{ width: '50%' }}>
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Please enter a name' }}
            render={({ field }) => (
              <TextField {...field} label="Name" fullWidth error={!!errors.name} helperText={errors.name?.message} />
            )}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="language"
            defaultValue="en"
            rules={{ required: 'Please select a language' }}
            render={({ field }) => (
              <FormControl component="fieldset">
                <FormLabel component="legend">What language is spoken in this recording?</FormLabel>
                <RadioGroup row {...field}>
                  <FormControlLabel value="en" control={<Radio />} label="English" />
                  <FormControlLabel value="et" control={<Radio />} label="Estonian" />
                </RadioGroup>
                {errors.language && <FormHelperText>{errors.language.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Tags"
            variant="outlined"
            fullWidth
            placeholder="Enter a tag and press Enter or any non-alphanumeric key"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onKeyUp={(event) => {
              // Use regex to remove any non-alphanumeric characters
              const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '').trim();

              // This regex checks if the key pressed is non-alphanumeric or if it's the Enter key
              const isDelimiter = /[^a-zA-Z0-9]/.test(event.key) || event.key === 'Enter';

              if (isDelimiter && sanitizedValue !== '') {
                // Only add the tag if it doesn't already exist in the array
                if (!tags.includes(sanitizedValue)) {
                  const newTags = [...tags, sanitizedValue];
                  setTags(newTags);
                  setValue('tags', newTags); // update the form value too
                }
                // Clear the text field regardless of whether it's a duplicate or not
                event.target.value = '';
              }
            }}
          />
          <Box mt={2}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => {
                  const newTags = tags.filter((t) => t !== tag);
                  setTags(newTags);
                  setValue('tags', newTags); // also update the form value
                }}
                style={{ marginRight: '5px', marginBottom: '5px' }}
              />
            ))}
          </Box>
          <Controller name="tags" control={control} render={() => null} defaultValue={[]} />
        </Grid>

        <Grid item xs={12}>
          <WundaButton variant="outlined" color="primary" onClick={onCancel} sx={{ mr: 2 }}>
            Cancel
          </WundaButton>
          <WundaButton type="submit" variant="contained" color="primary" disabled={loading}>
            Submit
          </WundaButton>
        </Grid>
      </Grid>
      <Box mt={2}>
        {loading && <GenericLoader label="Uploading..." />}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Box>
    </form>
  );
};

RecordingUploadForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default RecordingUploadForm;
