import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { logEvent } from 'firebase/analytics';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  Avatar,
  Tooltip,
  Rating,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import EventIcon from '@mui/icons-material/Event';
import StarIcon from '@mui/icons-material/Star';
import { useTheme } from '@mui/material/styles';
import Iconify from '../iconify';
import { analytics } from '../../firebase/firebase';
import DownloadInsightsFormatDialog from './DownloadInsightsFormatDialog';
import PromptDialog from '../dialog/PromptDialog';
import StatusProgressLabel from './StatusProgressLabel';
import WundaIconButton from './WundaIconButton';
import WundaShareToEmailDialog from './WundaShareToEmailDialog';
import WundaModal from './WundaModal';
import { apiRequest } from '../../api/api';

function InsightsReportCard({
  index,
  report,
  roleDialogue = [],
  onSuccess,
  onFailure,
  onDataRefresh,
  demoForAll = false,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentReport, setCurrentReport] = useState(report);
  const [showDownloadInsightsReportModal, setShowDownloadInsightsReportModal] = React.useState(false);
  const [downloadInsightsReportLoading, setDownloadInsightsReportLoading] = React.useState(false);
  const [showShareInsightsReportModal, setShowShareInsightsReportModal] = React.useState(false);
  const [shareInsightsReportLoading, setShareInsightsReportLoading] = React.useState(false);
  const [openInsightsDeleteDialog, setOpenInsightsDeleteDialog] = useState(false);
  const [openInsightsRetryDialog, setOpenInsightsRetryDialog] = useState(false);
  const [selectedDialogue, setSelectedDialogue] = useState(null);
  const [dialoguePreviewOpen, setDialoguePreviewOpen] = useState(false);
  const [insightsReportId, setInsightsReportId] = useState(null);
  const [scrollIndex, setScrollIndex] = useState(null);
  const isShared = currentReport.shareHistory && currentReport.shareHistory.length > 0;

  const dialogueContainerRef = useRef(null);
  const dialogueRefs = useRef([]);

  const defaultRating = (currentReport.ratings &&
    currentReport.ratings.find((rating) => rating.role === 'nurturer')) || {
    rating: 0,
  };
  const [ratingValue, setRatingValue] = useState(defaultRating.rating);
  const [ratingHover, setRatingHover] = React.useState(-1);

  const ratingLabels = {
    1: 'Poor',
    2: 'Below Average',
    3: 'Average',
    4: 'Good',
    5: 'Excellent',
  };

  const getRatingLabelText = (ratingValue) => ratingLabels[ratingValue] || '';

  useEffect(() => {
    if (dialoguePreviewOpen && selectedDialogue) {
      const index = roleDialogue.findIndex((dialogueItem) => dialogueItem.time === selectedDialogue.time);
      setScrollIndex(index);
    }
  }, [dialoguePreviewOpen, selectedDialogue, roleDialogue]);

  useLayoutEffect(() => {
    if (dialoguePreviewOpen && scrollIndex !== null && dialogueRefs.current[scrollIndex]) {
      const selectedElement = dialogueRefs.current[scrollIndex];
      setTimeout(() => {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
      }, 0);
    }
  }, [dialoguePreviewOpen, scrollIndex]);

  const handleSaveRating = useCallback(
    async (event, rating) => {
      event.preventDefault();
      setRatingValue(rating);
      const data = {
        rating,
        role: 'nurturer',
      };

      await apiRequest('POST', `/insights-report/rating/${currentReport._id}`, {
        data,
      })
        .then((response) => {
          logEvent(analytics, 'rate_insights_report', {
            reportId: response.report._id,
            rating,
            method: 'insights_report_card',
          });
          onSuccess('share', `Thank you! Your rating has been saved!`);
        })
        .catch((error) =>
          onFailure('rating', `Error saving the rating! ${error.response?.data?.message || error.message}`)
        );
    },
    [onFailure, onSuccess, currentReport._id]
  );

  const handleShareInsightsReport = async (email) => {
    try {
      await apiRequest('POST', `/insights-report/share/${currentReport._id}`, {
        data: {
          email,
        },
      });
      logEvent(analytics, 'share_insights_report', {
        reportId: currentReport._id,
        method: 'insights_report_card',
      });
      onSuccess('share', `Successfully shared the report with ${email}!`);
    } catch (error) {
      console.error(error);
      onFailure('share', `Error sharing a report! ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDownloadInsightsReport = async (format) => {
    try {
      let response;
      let downloadUrl;
      let fileExtension;

      if (format === 'pdf') {
        response = await apiRequest('GET', `/insights-report/download/pdf/${insightsReportId}`, {
          responseType: 'blob',
        });
        downloadUrl = URL.createObjectURL(response);
        fileExtension = 'pdf';
      } else {
        response = await apiRequest('GET', `/insights-report/download/${insightsReportId}`);
        downloadUrl = response.url;
        fileExtension = 'docx';
      }

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `report_${insightsReportId}.${fileExtension}`;
      document.body.appendChild(link);
      logEvent(analytics, 'download_insights_report', {
        reportId: insightsReportId,
        format,
        method: 'insights_report_card',
      });
      link.click();

      if (format === 'pdf') {
        URL.revokeObjectURL(downloadUrl); // Cleanup for blob URL
      }

      link.remove();
      onSuccess('download', `Successfully downloaded the report as ${fileExtension.toUpperCase()}!`);
    } catch (error) {
      console.error(error);
      onFailure('download', `Error downloading a report! ${error.response?.data?.message || error.message}`);
    }
  };

  const handleOpenDialoguePreviewModal = (time) => {
    if (roleDialogue) {
      const closestDialogue = findClosestDialogue(time, roleDialogue);
      setSelectedDialogue(closestDialogue);
      setDialoguePreviewOpen(true);
    }
  };

  const handleCloseDialoguePreviewModal = () => {
    setDialoguePreviewOpen(false);
    setScrollIndex(null);
  };

  const handleDeleteInsightsReportConfirmDialog = async () => {
    try {
      await apiRequest('DELETE', `insights-report/${insightsReportId}`);
      logEvent(analytics, 'delete_insights_report', {
        reportId: insightsReportId,
        method: 'insights_report_card',
      });
      onSuccess('delete', `Successfully deleted a report!`);
    } catch (error) {
      console.error(`Error deleting report ${insightsReportId}`, error);
      onFailure('delete', `Error deleting a report! ${error.response?.data?.message || error.message}`);
    } finally {
      setOpenInsightsDeleteDialog(false);
    }
  };

  const handleInsightsRetryConfirmDialog = async () => {
    try {
      await apiRequest('POST', `insights-report/retry/${insightsReportId}`);
      logEvent(analytics, 'retry_insights_report', {
        reportId: insightsReportId,
        method: 'insights_report_card',
      });
      onSuccess('retry', 'Successfully triggered retry of the report!');
    } catch (error) {
      console.error(`Error retrying report ${insightsReportId}`, error);
      onFailure('retry', `Error retrying a report! ${error.response?.data?.message || error.message}`);
    } finally {
      setOpenInsightsRetryDialog(false);
    }
  };

  const handleNurturerReportStatusChange = useCallback((newStatus, reportId) => {
    setCurrentReport((r) => {
      if (r._id === reportId) {
        return { ...r, status: newStatus };
      }
      return r;
    });
  }, []);

  const handleInsightsRetryConfirm = (id) => {
    setInsightsReportId(id);
    setOpenInsightsRetryDialog(true);
  };

  const handleInsightsRetryCancel = () => {
    setOpenInsightsRetryDialog(false);
  };

  const openDownloadInsightsReportDialog = (reportId) => {
    setInsightsReportId(reportId);
    setShowDownloadInsightsReportModal(true);
  };

  const handleDownloadInsightsReportCancel = () => {
    setShowDownloadInsightsReportModal(false);
  };

  const handleDownloadInsightsReportConfirm = async (format) => {
    setDownloadInsightsReportLoading(true);
    await handleDownloadInsightsReport(format);
    setDownloadInsightsReportLoading(false);
    setShowDownloadInsightsReportModal(false);
  };

  const openShareInsightsReportDialog = (reportId) => {
    setInsightsReportId(reportId);
    setShowShareInsightsReportModal(true);
  };

  const handleShareInsightsReportCancel = () => {
    setShowShareInsightsReportModal(false);
  };

  const handleShareInsightsReportConfirm = async (email) => {
    setShareInsightsReportLoading(true);
    await handleShareInsightsReport(email);
    setShareInsightsReportLoading(false);
    setShowShareInsightsReportModal(false);
  };

  const handleDeleteInsightsReportConfirm = (reportId) => {
    setInsightsReportId(reportId);
    setOpenInsightsDeleteDialog(true);
  };

  const handleDeleteInsightsReportCancel = () => {
    setOpenInsightsDeleteDialog(false);
  };

  // Helper function to convert time string to seconds
  function timeToSeconds(time) {
    const parts = time.split(':').map((part) => Number(part.replace(/[^\d.-]/g, '')));
    let seconds = 0;

    if (parts.length === 3) {
      // If time string is in the format "HH:MM:SS"
      seconds += parts[0] * 3600; // hours
      seconds += parts[1] * 60; // minutes
      seconds += parts[2]; // seconds
    } else if (parts.length === 2) {
      // If time string is in the format "MM:SS"
      seconds += parts[0] * 60; // minutes
      seconds += parts[1]; // seconds
    }

    return seconds;
  }

  // Helper function to find the closest dialogue item
  function findClosestDialogue(time, dialogueArray) {
    if (!dialogueArray || dialogueArray.length === 0) {
      return null;
    }

    const target = timeToSeconds(time);
    let closest = dialogueArray[0];
    let closestDiff = Math.abs(timeToSeconds(closest.time) - target);

    for (let i = 1; i < dialogueArray.length; i += 1) {
      const diff = Math.abs(timeToSeconds(dialogueArray[i].time) - target);
      if (diff < closestDiff) {
        closest = dialogueArray[i];
        closestDiff = diff;
      }
    }

    return closest;
  }

  const RenderWithHighlight = ({ text }) => {
    const parts = text.split(/\b(\d+m:\d+s)\b/g);
    return (
      <span>
        {parts.map((part, index) =>
          /\d+m:\d+s/.test(part) ? (
            <button
              key={index}
              type="button"
              style={{ backgroundColor: theme.palette.secondary.main, cursor: 'pointer', border: 'none', padding: 0 }}
              onClick={() => handleOpenDialoguePreviewModal(part)}
              onKeyDown={() => handleOpenDialoguePreviewModal(part)}
            >
              {part}
            </button>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  function formatReportContentJSON(content) {
    return (
      <Box>
        <Typography variant="body1">{content.description}</Typography>
        {content.sections.map((section, index) => (
          <Box key={index} mt={1}>
            <Box display="flex" alignItems="center" justifyContent="space-between" bgcolor="rgba(0, 0, 0, 0.04)">
              <Typography variant="subtitle1">{`${index + 1}. ${section.sectionTitle}`}</Typography>
              {section.items.some((item) => item.label.toLowerCase() === 'evidence') && (
                <Box display="flex" alignItems="center" ml={1}>
                  {section.items.find((item) => item.label.toLowerCase() === 'evidence').value === 'true' ? (
                    <Iconify icon={'openmoji:check-mark'} />
                  ) : (
                    <Iconify icon={'openmoji:cross-mark'} />
                  )}
                </Box>
              )}
            </Box>
            <Box mt={0}>
              <List sx={{ pt: 0 }}>
                {section.items.map((item, itemIndex) => (
                  <ListItem key={itemIndex} sx={{ pt: 0 }}>
                    <ListItemText
                      primary={
                        item.label.toLowerCase() !== 'evidence' && (
                          <Typography variant="body1">{`${item.label}: ${item.value}`}</Typography>
                        )
                      }
                      secondary={
                        <Typography variant="body1" component="span">
                          <RenderWithHighlight text={item.details} />
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        ))}
        {content.summary && (
          <Box mt={2}>
            <Typography variant="subtitle1">{content.summary.label}</Typography>
            <Typography variant="body1">{content.summary.value}</Typography>
          </Box>
        )}
        {content.additionalInfo.map((info, index) => (
          <Box key={index} mt={2}>
            <Typography variant="subtitle1">{info.label}</Typography>
            <Typography variant="body1">{info.value}</Typography>
          </Box>
        ))}
      </Box>
    );
  }

  function formatReportContent(content) {
    // If the content doesn't contain any bullet points, return it directly
    if (!content.split('\n').some((line) => line.trim().startsWith('-'))) {
      return <p>{content}</p>;
    }

    // Split the content by new lines to identify categories and items
    const sections = content.split('\n').filter((line) => line.trim() !== '');

    // Initialize an array to hold JSX elements and an object to hold the current category
    const formattedContent = [];
    let currentCategory = { title: '', items: [] };

    sections.forEach((section, index) => {
      if (!section.trim().startsWith('-')) {
        // If there's an existing category with items, push it to formattedContent
        if (currentCategory.items.length > 0) {
          formattedContent.push(currentCategory);
        }
        // Start a new category
        let title = section.trim();
        title = title.replace(/[#*]/g, '').trim(); // updated regex
        currentCategory = { title, items: [] };
      } else {
        // This is an item. Remove the bullet point and add to the current category's items
        const itemContent = section.trim().substring(1).trim();
        currentCategory.items.push(itemContent);
      }
      // Push the last category when it's the last section
      if (index === sections.length - 1 && currentCategory.items.length > 0) {
        formattedContent.push(currentCategory);
      }
    });

    // Now map the categories and items to JSX
    const listJSX = formattedContent.map((category, index) => {
      const titleParts = category.title.split(':');
      return (
        <Box key={index} sx={{ mb: 2 }}>
          {titleParts[1] ? (
            <Typography variant="body1" gutterBottom component="div">
              <Typography variant="h6" component="div">
                <ReactMarkdown components={{ p: ({ ...props }) => <p {...props} style={{ margin: '0' }} /> }}>
                  {`${titleParts[0]}:`}
                </ReactMarkdown>
              </Typography>
              <ReactMarkdown components={{ p: ({ ...props }) => <p {...props} style={{ margin: '0' }} /> }}>
                {titleParts[1].trim()}
              </ReactMarkdown>
            </Typography>
          ) : (
            <Typography variant="h6" gutterBottom component="div">
              <ReactMarkdown components={{ p: ({ ...props }) => <p {...props} style={{ margin: '0' }} /> }}>
                {category.title}
              </ReactMarkdown>
            </Typography>
          )}
          <ul>
            {category.items.map((item, itemIndex) => (
              <li key={itemIndex}>
                <RenderWithHighlight text={item} />
              </li>
            ))}
          </ul>
        </Box>
      );
    });

    return <div>{listJSX}</div>;
  }

  return (
    <Container component="main" sx={{ px: 0 }}>
      <Card key={index} sx={{ mb: 2 }}>
        <CardContent>
          <Box key={index} sx={{ mb: 2 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              {!isMobile && (
                <Grid item xs={12} sm={3}>
                  <Avatar sx={{ mr: 2, bgcolor: 'primary.light' }}> </Avatar>
                </Grid>
              )}
              <Grid item xs={12} sm={isMobile ? 9 : 6}>
                <Box display="flex" justifyContent="left">
                  <Typography variant="h6" color="text.primary" className="fs-mask">
                    {`${currentReport.sessionName}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={isMobile ? 3 : 3}>
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                  {currentReport.status === 'COMPLETED' && (
                    <>
                      <WundaIconButton
                        onClick={() => openShareInsightsReportDialog(currentReport._id)}
                        tooltip="Share"
                        size="small"
                        icon="eva:share-outline"
                      />
                      <WundaIconButton
                        onClick={() => openDownloadInsightsReportDialog(currentReport._id)}
                        tooltip="Download"
                        size="small"
                        icon="eva:download-outline"
                      />
                    </>
                  )}
                  {currentReport.status === 'FAILED' && (
                    <WundaIconButton
                      onClick={() => handleInsightsRetryConfirm(currentReport._id)}
                      tooltip="Retry"
                      size="small"
                      icon="eva:refresh-outline"
                    />
                  )}
                  <WundaIconButton
                    onClick={demoForAll ? null : () => handleDeleteInsightsReportConfirm(currentReport._id)}
                    tooltip={demoForAll ? 'Remove this demo report (disabled)' : 'Delete'}
                    size="small"
                    icon="eva:trash-2-outline"
                    disabled={demoForAll}
                  />
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{ mb: 2, mt: 1 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <EventIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                  <Tooltip title="Generated at">
                    <Typography variant="body2" color="text.secondary">
                      {currentReport.createdAt
                        ? new Date(currentReport.createdAt).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                          })
                        : ''}
                    </Typography>
                  </Tooltip>
                </Box>
                {currentReport.nurturerName && (
                  <Typography variant="body2" color="text.secondary" className="fs-mask">
                    Coach: {currentReport.nurturerName}
                  </Typography>
                )}
                {currentReport.flourisherName && (
                  <Typography variant="body2" color="text.secondary" className="fs-mask">
                    Coachee: {currentReport.flourisherName}
                  </Typography>
                )}
              </Box>
              {currentReport.status !== 'COMPLETED' && (
                <Box display="flex" alignItems="center" gap={1}>
                  <StatusProgressLabel
                    key={`${currentReport._id}-${currentReport.status}-${currentReport.lastError ? 'err' : 'ok'}`}
                    status={currentReport.status}
                    url={`insights-report/status/${currentReport._id}`}
                    onStatusChange={handleNurturerReportStatusChange}
                    onReady={onDataRefresh}
                    pollingInterval={15}
                    showError={
                      currentReport.status === 'FAILED' && currentReport.lastError ? currentReport.lastError : null
                    }
                  />
                </Box>
              )}
              {isShared && (
                <Tooltip title="This report has been shared with somebody">
                  <Iconify icon={'ri:user-shared-line'} />
                </Tooltip>
              )}
            </Box>
            {currentReport.status === 'COMPLETED' &&
              currentReport.sections.map((section, sectionIndex) => (
                <Box key={sectionIndex} sx={{ mt: 2 }}>
                  <Typography variant="h4" color="text.primary">
                    {section.heading}
                  </Typography>
                  <Typography variant="body2" component="div" color="text.secondary" sx={{ mt: 1 }} className="fs-mask">
                    {currentReport.templateFormat === 'json'
                      ? formatReportContentJSON(section.jsonContent)
                      : formatReportContent(section.content)}
                  </Typography>
                </Box>
              ))}
            {currentReport.status === 'COMPLETED' && !demoForAll && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 2,
                }}
              >
                <Typography variant="body2">Rate these insights</Typography>
                <Rating
                  name="hover-feedback"
                  value={ratingValue}
                  precision={1}
                  getLabelText={getRatingLabelText}
                  onChange={handleSaveRating}
                  onChangeActive={(event, newHover) => {
                    setRatingHover(newHover);
                  }}
                  emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  sx={{ color: theme.palette.primary.light }}
                />
                <Typography variant="body2" sx={{ ml: 2 }} color={theme.palette.primary.main}>
                  {ratingValue !== null
                    ? ratingLabels[ratingHover !== -1 ? ratingHover : ratingValue]
                    : ratingLabels[ratingValue]}
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>

      <DownloadInsightsFormatDialog
        open={showDownloadInsightsReportModal}
        isLoading={downloadInsightsReportLoading}
        onCancel={handleDownloadInsightsReportCancel}
        onConfirm={handleDownloadInsightsReportConfirm}
      />

      <WundaShareToEmailDialog
        dialogProps={{
          title: 'Send to e-mail',
          bodyText: 'Enter the email address to share the report with',
          confirmPromptText: 'Are you sure you want to share this report to {email}?',
        }}
        open={showShareInsightsReportModal}
        isLoading={shareInsightsReportLoading}
        onCancel={handleShareInsightsReportCancel}
        onConfirm={handleShareInsightsReportConfirm}
      />

      <PromptDialog
        open={openInsightsDeleteDialog}
        title="Confirm Deletion"
        message="Are you sure you want to delete this insights report?"
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onCancel={handleDeleteInsightsReportCancel}
        onConfirm={handleDeleteInsightsReportConfirmDialog}
      />

      <PromptDialog
        open={openInsightsRetryDialog}
        title="Confirm Retry"
        message="Are you sure you want to retry generating this report?"
        cancelButtonLabel="Never mind"
        confirmButtonLabel="Yes"
        onCancel={handleInsightsRetryCancel}
        onConfirm={handleInsightsRetryConfirmDialog}
      />

      <WundaModal
        open={dialoguePreviewOpen}
        onClose={handleCloseDialoguePreviewModal}
        showCloseButton
        sx={{
          '& .MuiDialog-paper': {
            width: { xs: '90%', sm: '50%' },
            maxHeight: '90vh',
            overflow: 'hidden',
          },
        }}
      >
        <Box ref={dialogueContainerRef} sx={{ overflow: 'auto', maxHeight: '80vh' }}>
          {roleDialogue &&
            selectedDialogue &&
            Array.isArray(roleDialogue) &&
            roleDialogue.map((dialogueItem, index) => (
              <Box
                key={index}
                ref={(el) => {
                  dialogueRefs.current[index] = el;
                }}
                sx={{ mb: 2 }}
                bgcolor={dialogueItem.time === selectedDialogue.time ? theme.palette.secondary.main : 'transparent'}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle1" color="text.primary">
                    {dialogueItem.speaker.charAt(0).toUpperCase() + dialogueItem.speaker.slice(1)}
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                    ({dialogueItem.startTime ? new Date(dialogueItem.startTime * 1000).toISOString().slice(11, 19) : ''}{' '}
                    -{dialogueItem.endTime ? new Date(dialogueItem.endTime * 1000).toISOString().slice(11, 19) : ''})
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {dialogueItem.text}
                </Typography>
              </Box>
            ))}
        </Box>
      </WundaModal>
    </Container>
  );
}

InsightsReportCard.propTypes = {
  index: PropTypes.number.isRequired,
  report: PropTypes.object.isRequired,
  roleDialogue: PropTypes.array,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onDataRefresh: PropTypes.func,
  text: PropTypes.string,
  demoForAll: PropTypes.bool,
};

export default InsightsReportCard;
