import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Iconify from '../iconify';

const WundaIconButton = ({
  onClick,
  icon,
  tooltip,
  size = 'medium',
  iconSize,
  tooltipPlacement = 'bottom',
  ...props
}) =>
  tooltip ? (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <IconButton onClick={onClick} size={size} {...props}>
        {iconSize ? <Iconify icon={icon} width={iconSize} height={iconSize} /> : <Iconify icon={icon} />}
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton onClick={onClick} size={size} {...props}>
      {iconSize ? <Iconify icon={icon} width={iconSize} height={iconSize} /> : <Iconify icon={icon} />}
    </IconButton>
  );

WundaIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  iconSize: PropTypes.number,
  tooltipPlacement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
};

export default WundaIconButton;
