import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, Stack, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../providers/AuthProvider';
import ErrorMessage from '../components/alert/ErrorMessage';
import WundaButton from '../components/wundamental/WundaButton';

const SubscriptionReturnPage = () => {
  const { currentUser, refreshBalance } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasRefreshed, setHasRefreshed] = useState(false);

  // TODO: Trigger subscription check in header. As it may sometimes take a while for the subscription to be updated, we should trigger a spinner or please wait copy in credit balance header
  if (!hasRefreshed) {
    refreshBalance();
    setHasRefreshed(true);
  }

  return (
    <Container maxWidth={false}>
      <Helmet>
        <title> Subscription Complete | Wundamental </title>
      </Helmet>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        {errorMessage ? (
          <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
        ) : (
          <Typography variant="h4" gutterBottom>
            {'Subscription Successful!'}
          </Typography>
        )}
      </Stack>
      <Paper elevation={3} sx={{ p: 2, my: 3, width: '100%' }}>
        <Typography variant="h6" gutterBottom>
          {currentUser.DBuser?.workspace ? 'Your workspace is all set up!' : 'You are all set up!'}
        </Typography>
        <Typography variant="body2" gutterBottom>
          A confirmation email will be sent to your email.
        </Typography>
        <Typography variant="body2" gutterBottom>
          We are here for you, might you have questions:{' '}
          <a href="mailto:support@wundamental.ai">support@wundamental.ai</a>.
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <WundaButton variant="contained" color="primary" onClick={() => navigate('/home/sessions')}>
            Continue
          </WundaButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default SubscriptionReturnPage;
