import { Helmet } from 'react-helmet-async';
import { logEvent } from 'firebase/analytics';
import { styled, useTheme } from '@mui/material/styles';
import { Link, Container, Typography, Box, Alert } from '@mui/material';
import { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendSignInLinkToEmail, fetchSignInMethodsForEmail, GoogleAuthProvider } from 'firebase/auth';
import { analytics } from '../firebase/firebase';
import Iconify from '../components/iconify';
import { logMessage } from '../utils/logger';
import { AuthContext, doesUserEmailExist, signInWithGoogleRedirect } from '../providers/AuthProvider';
import useResponsive from '../hooks/useResponsive';
import Logo from '../components/logo';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import GenericLoader from '../components/loader/GenericLoader';
import ErrorMessage from '../components/alert/ErrorMessage';
import EmailSigninConfirm from '../components/wundamental/EmailSigninConfirm';
import WundaButton from '../components/wundamental/WundaButton';
// sections
import LoginForm from '../sections/auth/LoginForm';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  const auth = getAuth();
  const theme = useTheme();
  const { resetAuthInProgress, forceLogout, redirectResult, handleUserLogin } = useContext(AuthContext);
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const [emailFormSubmitted, setEmailFormSubmitted] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userDataError, setUserDataError] = useState(null);
  const snackbarRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigateToSignup = (user) => {
    navigate('/signup', {
      state: { email: user.email, fullName: user.displayName, firebaseUid: user.uid },
    });
  };

  const handleError = (error, message) => {
    setUserDataError(`${message} ${error.message}`);
  };

  useEffect(() => {
    const authenticateUser = async () => {
      const googleAuthInProgress = localStorage.getItem('googleAuthInProgress');
      if (googleAuthInProgress && redirectResult && redirectResult.user) {
        try {
          setLoading(true);
          localStorage.setItem('googleAuthInProgress', 'true');
          await handleUserLogin(redirectResult.user);
          logEvent(analytics, 'login', { method: 'google' });
          navigate('/home');
        } catch (error) {
          if (error.message === 'User not found') {
            navigateToSignup(redirectResult.user);
          } else {
            await forceLogout();
            handleError(error, 'Error signing in with Google!');
          }
        } finally {
          setLoading(false);
        }
      }
      resetAuthInProgress();
    };

    authenticateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetStartedClick = () => {
    navigate('/signup');
  };

  const showSuccess = (message) => {
    snackbarRef.current.showSnackbar({ message, severity: 'success' });
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    localStorage.setItem('googleAuthInProgress', 'true');
    try {
      await signInWithGoogleRedirect();
    } catch (error) {
      handleError(error, 'Error signing in with Google!');
      await forceLogout();
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      // const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      setAuthError(`Error signing in with Google (${credential})! ${errorCode} : ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (email) => {
    if (!email) {
      setAuthError('Email is required.');
      return;
    }

    const actionCodeSettings = {
      url: `${window.location.origin}/auth/callback/`,
      handleCodeInApp: true,
    };

    try {
      const userExists = await doesUserEmailExist(email);
      if (!userExists) {
        throw new Error('No account found with this email. Please sign up first.');
      }

      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      logMessage('DEBUG', `signInMethods for ${email}: ${signInMethods}`);

      if (signInMethods.length === 0) {
        // throw new Error('No account found with this email. Please sign up first.');
        console.error('No signin methods found for this email. Will still send email link...');
      } else if (signInMethods.length > 0 && !signInMethods.includes('emailLink')) {
        throw new Error('Email link login not enabled for this account. Please use Google account or sign up first.');
      }

      localStorage.setItem('emailAuthInProgress', 'true');
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      logEvent(analytics, 'login', { method: 'email' });
      setEmailFormSubmitted(true);
    } catch (error) {
      setAuthError(`Error signing in! ${error.response?.data?.message || error.message}`);
    }
  };

  if (loading) {
    return <GenericLoader label="Signing in..." />;
  }

  return (
    <>
      <Helmet>
        <title> Login | Wundamental </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
          <StyledContent>
            {!emailFormSubmitted ? ( // Render the form if it's not submitted
              <>
                <Typography variant="h4" gutterBottom>
                  Sign in to Wundamental
                </Typography>

                <Typography variant="body2" sx={{ mb: 5 }}>
                  Don’t have an account?{' '}
                  <Link to="/signup" component={Link} onClick={handleGetStartedClick} sx={{ cursor: 'pointer' }}>
                    Sign up now
                  </Link>
                </Typography>

                {userDataError && <Alert severity="error">{userDataError}</Alert>}
                <LoginForm onLogin={handleLogin} error={authError} />

                <Box mt={2} display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="body1">or</Typography>
                  <Box mt={2}>
                    <WundaButton
                      startIcon={<Iconify icon="logos:google-icon" />}
                      variant="contained"
                      color="primary"
                      onClick={handleGoogleLogin}
                      size="large"
                      sx={{
                        backgroundColor: '#ffffff',
                        color: theme.palette.text.primary,
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                    >
                      Sign in with Google
                    </WundaButton>
                  </Box>
                </Box>
              </>
            ) : (
              // Show success message if the form is submitted
              <>
                <EmailSigninConfirm
                  title="Check your inbox for the login link."
                  body="We have sent an email to your registered email address with a link to sign in."
                  onSuccess={() => showSuccess('Email sign-in link sent successfully')}
                  onFailure={() => setErrorMessage('Failed to send e-mail link. Please try again later.')}
                />
              </>
            )}
          </StyledContent>
        </Container>
      </StyledRoot>

      <GenericSnackbar ref={snackbarRef} />
    </>
  );
}
