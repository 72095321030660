import React, { useEffect, useContext } from 'react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// FullStory
import { FullStory, init as FSinit } from '@fullstory/browser';
// routes
import { setUserId } from 'firebase/analytics';
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { LoaderProvider } from './providers/LoaderProvider';
import { AuthContext } from './providers/AuthProvider';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import packageJson from '../package.json';
import { analytics } from './firebase/firebase';

// ----------------------------------------------------------------------

function ErrorHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleGlobalError = (event) => {
      if (event.detail.type === 'NO_CONNECTION') {
        navigate('/NoConnection');
      }
    };

    window.addEventListener('globalError', handleGlobalError);

    return () => window.removeEventListener('globalError', handleGlobalError);
  }, [navigate]);

  return null;
}

export default function App() {
  const FSOrgID = process.env.REACT_APP_FULLSTORY_ORGID;
  const { currentUser } = useContext(AuthContext);

  // Initialize FullStory with your organization ID
  if (FSOrgID) {
    FSinit({ orgId: FSOrgID });
  }

  useEffect(() => {
    // Set the user ID using the setUserId method
    if (currentUser) {
      setUserId(analytics, currentUser.DBuser?._id);
    }

    if (FSOrgID) {
      if (currentUser) {
        FullStory('setIdentity', {
          uid: currentUser.DBuser?._id,
        });
      } else {
        FullStory('setIdentity', { anonymous: true });
      }
    }
  }, [FSOrgID, currentUser]);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <LoaderProvider>
            <ScrollToTop />
            <StyledChart />
            <ErrorHandler />
            <Helmet>
              <meta name="version" content={packageJson.version} />
            </Helmet>
            <Router />
          </LoaderProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
