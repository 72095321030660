import { useState, useEffect } from 'react';
import { TextField, Grid, Alert, Box, Chip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import GenericLoader from '../../../components/loader/GenericLoader';
import { apiRequest } from '../../../api/api';
import WundaButton from '../../../components/wundamental/WundaButton';

const RecordingEditForm = ({ row, onCancel, onSuccess }) => {
  const methods = useForm({
    defaultValues: {
      name: row ? row.name : '',
      sessionDate: row && row.sessionDate ? dayjs(row.sessionDate) : null,
    },
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState(row ? row.tags : []);
  const [tagInputValue, setTagInputValue] = useState('');

  const recordingId = row ? row._id : null;
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = methods;

  useEffect(() => {
    if (row) {
      setValue('recordingId', row._id);
      setValue('Name', row.name);
      setValue('sessionDate', row.sessionDate);
      setValue('tags', row.tags || []);
      setTags(row.tags || []);
    }
  }, [row, setValue]);

  const handleEditRecording = async (data) => {
    const { recordingId, name, tags } = data;

    const payload = {
      name,
      tags,
    };

    try {
      setLoading(true);

      // We're using 'PUT' here because it's an update operation
      await apiRequest('PUT', `/audio/${recordingId}`, {
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 300000,
      });

      setSuccessMessage('Successfully updated the recording');
      reset();

      if (onSuccess) {
        onSuccess('Successfully updated the recording');
      }

      if (onCancel) {
        onCancel();
      }
    } catch (error) {
      console.error('Failed to update the recording:', error);
      setErrorMessage(`Error updating the recording! ${error.response?.data?.message || error}`);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (tagInputValue.trim() !== '' && !tags.includes(tagInputValue.trim())) {
      setErrorMessage(
        'You forgot to add a tag. Please put a cursor in "Tag" field, press Enter or any non-alphanumeric key to add it.'
      );
      return;
    }
    handleSubmit(
      async (data) => {
        try {
          await handleEditRecording(data);
        } catch (error) {
          setErrorMessage(error.message || 'An unexpected error occurred.');
        }
      },
      (errors) => {
        console.log('Form validation errors:', errors);
        setErrorMessage('There were some errors with your submission. Did you fill all required fields?');
      }
    )();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Typography variant="h6" sx={{ mb: 5 }}>
          Recording ID: {recordingId}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              rules={{ required: 'Please name your recording' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name *"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Tags"
              variant="outlined"
              fullWidth
              placeholder="Enter a tag and press Enter or any non-alphanumeric key"
              value={tagInputValue} // bind the state variable to the input value
              onChange={(e) => setTagInputValue(e.target.value)} // update the state variable on every change
              onBlur={() => {
                if (tagInputValue.trim() !== '' && !tags.includes(tagInputValue.trim())) {
                  setErrorMessage(
                    'You forgot to add a tag. Please put a cursor in "Tag" field, press Enter or any non-alphanumeric key to add it.'
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              onKeyUp={(event) => {
                const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '').trim();
                const isDelimiter = /[^a-zA-Z0-9]/.test(event.key) || event.key === 'Enter';

                if (isDelimiter && sanitizedValue !== '') {
                  if (!tags.includes(sanitizedValue)) {
                    const newTags = [...tags, sanitizedValue];
                    setTags(newTags);
                    setValue('tags', newTags);
                  }
                  setTagInputValue(''); // reset the input value state
                  event.target.value = '';
                }
              }}
            />
            <Box mt={2}>
              {tags &&
                tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => {
                      const newTags = tags.filter((t) => t !== tag);
                      setTags(newTags);
                      setValue('tags', newTags);
                    }}
                    style={{ marginRight: '5px', marginBottom: '5px' }}
                  />
                ))}
            </Box>
            <Controller name="tags" control={control} render={() => null} defaultValue={[]} />
          </Grid>

          <Grid item xs={12}>
            <WundaButton variant="outlined" color="primary" onClick={onCancel} sx={{ mr: 2 }}>
              Cancel
            </WundaButton>
            <WundaButton type="submit" variant="contained" color="primary" disabled={loading}>
              Save
            </WundaButton>
          </Grid>
        </Grid>
        <Box mt={2}>
          {loading && <GenericLoader />}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
      </form>
    </FormProvider>
  );
};

RecordingEditForm.propTypes = {
  row: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default RecordingEditForm;
