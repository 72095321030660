import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 250,
        height: 70,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg width="646" height="88" viewBox="0 0 646 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M44 88C68.3005 88 88 68.3005 88 44C88 19.6995 68.3005 0 44 0C19.6995 0 0 19.6995 0 44C0 68.3005 19.6995 88 44 88Z"
          fill="#D4FF51"
        />
        <path
          d="M47.6824 62.3622C43.6239 62.3622 40.3408 59.0791 40.3408 55.0206V40.3209C44.3993 40.3209 47.6824 43.604 47.6824 47.6625V62.3622Z"
          fill="#6600FF"
        />
        <path
          d="M25.6155 40.3206C25.6155 36.2621 22.3324 32.979 18.2739 32.979V55.0203C18.2739 59.0788 21.557 62.3619 25.6155 62.3619V40.3206Z"
          fill="#6600FF"
        />
        <path
          d="M29.3022 51.3418V55.0208C29.3022 59.0793 32.5853 62.3624 36.6438 62.3624V58.6834C36.6438 54.6249 33.3607 51.3418 29.3022 51.3418Z"
          fill="#6600FF"
        />
        <path
          d="M51.3438 47.6791V55.0206C51.3438 59.0791 54.6268 62.3622 58.6853 62.3622V55.0206C58.6853 50.9621 55.4022 47.6791 51.3438 47.6791Z"
          fill="#6600FF"
        />
        <path
          d="M69.7239 32.9788C69.7239 28.9203 66.4408 25.6372 62.3823 25.6372V55.0366C62.3823 59.0951 65.6654 62.3782 69.7239 62.3782V32.9788Z"
          fill="#6600FF"
        />
        <path
          d="M152.321 57.2886L143.635 37.7881H138.124L129.504 57.6012L121.41 31.831L112 31.8968L125.21 71.2268H131.708L140.592 49.4225L149.903 71.2268H156.483L169.759 31.8968H160.349L152.321 57.2886Z"
          fill="#0F084B"
        />
        <path
          d="M206.464 55.4777C206.464 56.7283 206.234 57.8803 205.79 58.9499C205.345 60.0195 204.72 60.9411 203.931 61.7145C203.141 62.488 202.203 63.0804 201.134 63.5082C200.065 63.9361 198.88 64.15 197.597 64.15C195.853 64.15 194.356 63.7551 193.122 62.9487C191.872 62.1588 190.934 61.0234 190.293 59.5588C189.651 58.0942 189.322 56.3169 189.322 54.227V31.9126H180.143V56.1688C180.143 59.46 180.685 62.274 181.788 64.6437C182.873 67.0134 184.469 68.8235 186.558 70.0907C188.648 71.3578 191.115 71.9996 193.945 71.9996C196.133 71.9996 198.189 71.5553 200.13 70.6995C202.072 69.8438 203.75 68.659 205.164 67.1944C205.707 66.6349 206.168 66.0425 206.596 65.4171L206.694 71.259H215.644V31.929H206.464V55.5106V55.4777Z"
          fill="#0F084B"
        />
        <path
          d="M259.173 32.8682C257.166 31.6669 254.665 31.0745 251.671 31.0745C249.582 31.0745 247.542 31.5188 245.552 32.4239C243.561 33.3125 241.85 34.4974 240.402 35.9619C239.925 36.4392 239.531 36.9493 239.169 37.4594L239.07 31.8973H230.121V71.2273H239.3V47.4977C239.3 46.2964 239.531 45.2103 240.008 44.2064C240.485 43.2191 241.126 42.3304 241.949 41.557C242.771 40.7836 243.742 40.1912 244.861 39.7633C245.979 39.3354 247.213 39.1215 248.546 39.1215C250.191 39.0721 251.573 39.3519 252.724 39.9772C253.876 40.6026 254.715 41.5735 255.258 42.89C255.8 44.2064 256.08 45.8685 256.08 47.8597V71.2273H265.26V47.2015C265.26 43.614 264.75 40.6355 263.73 38.2493C262.71 35.8632 261.196 34.0695 259.173 32.8682Z"
          fill="#0F084B"
        />
        <path
          d="M307.49 36.422C306.848 35.7144 306.108 35.0232 305.252 34.3979C303.854 33.3776 302.275 32.5713 300.514 31.9788C298.754 31.3864 296.912 31.0902 295.036 31.0902C291.549 31.0902 288.439 31.9788 285.676 33.7396C282.912 35.5004 280.74 37.9359 279.145 41.0132C277.549 44.107 276.759 47.6121 276.759 51.5451C276.759 55.4781 277.565 59.0491 279.178 62.1099C280.79 65.1707 282.978 67.5898 285.741 69.3506C288.505 71.1114 291.631 72 295.102 72C296.895 72 298.639 71.7202 300.366 71.1443C302.077 70.5683 303.591 69.8113 304.874 68.8734C305.976 68.067 306.897 67.1784 307.654 66.2239L307.769 71.2595H316.653V16H307.473V36.422H307.49ZM306.404 58.0946C305.483 59.9871 304.199 61.4846 302.554 62.5707C300.909 63.6732 299.017 64.2163 296.879 64.2163C294.74 64.2163 292.848 63.6732 291.203 62.5707C289.558 61.4846 288.275 59.9871 287.354 58.0946C286.432 56.2022 285.972 54.0135 285.972 51.5287C285.972 49.0438 286.432 46.921 287.354 44.9956C288.275 43.0867 289.558 41.5892 291.203 40.5195C292.848 39.4499 294.74 38.9068 296.879 38.9068C299.017 38.9068 300.909 39.4499 302.554 40.5195C304.199 41.5892 305.483 43.0867 306.404 44.9956C307.325 46.9045 307.786 49.0932 307.786 51.5287C307.786 53.9641 307.325 56.2022 306.404 58.0946Z"
          fill="#0F084B"
        />
        <path
          d="M358.817 37.4428C358.06 36.3074 357.139 35.2706 356.053 34.3655C354.803 33.3124 353.372 32.506 351.727 31.9465C350.081 31.3705 348.272 31.0908 346.281 31.0908C342.843 31.0908 339.767 31.963 337.036 33.7073C334.305 35.4516 332.133 37.8542 330.538 40.948C328.942 44.0417 328.152 47.5469 328.152 51.4634C328.152 55.3799 328.942 58.9015 330.538 61.9788C332.133 65.0726 334.239 67.5081 336.871 69.2853C339.503 71.0626 342.448 71.9677 345.673 71.9677C347.564 71.9677 349.341 71.6715 351.003 71.079C352.664 70.4866 354.178 69.6967 355.56 68.7258C356.81 67.8372 357.863 66.8334 358.735 65.6979V71.2271H368.062V31.8971H358.817V37.4428ZM353.898 62.275C352.253 63.3282 350.361 63.8384 348.222 63.8384C346.084 63.8384 344.208 63.3118 342.596 62.275C340.984 61.2383 339.717 59.7737 338.829 57.9142C337.941 56.0546 337.48 53.8989 337.48 51.4634C337.48 49.0279 337.924 46.9544 338.829 45.1114C339.717 43.2683 340.984 41.8201 342.596 40.7834C344.208 39.7467 346.084 39.2201 348.222 39.2201C350.361 39.2201 352.253 39.7467 353.898 40.7834C355.543 41.8201 356.826 43.2683 357.748 45.1114C358.669 46.9544 359.129 49.0608 359.129 51.4634C359.129 53.866 358.669 56.0546 357.748 57.9142C356.826 59.7737 355.543 61.2383 353.898 62.275Z"
          fill="#0F084B"
        />
        <path
          d="M436.12 33.0152C434.13 31.8139 431.645 31.2215 428.651 31.2215C427.302 31.2215 425.953 31.419 424.588 31.8139C423.223 32.2089 421.907 32.8013 420.673 33.5747C419.422 34.3482 418.304 35.2203 417.317 36.1912C416.445 37.047 415.754 37.985 415.227 39.0052C414.734 37.6229 414.092 36.4216 413.319 35.4178C412.2 33.9697 410.802 32.9165 409.141 32.2418C407.479 31.5671 405.571 31.238 403.432 31.238C401.984 31.238 400.57 31.4683 399.171 31.9456C397.773 32.4228 396.457 33.0646 395.223 33.8874C393.973 34.7102 392.903 35.6811 392.015 36.8001C391.818 37.047 391.637 37.3103 391.456 37.5736L391.341 31.9127H382.309V71.2427H391.555V47.6611C391.555 46.4598 391.785 45.3737 392.229 44.3699C392.673 43.3825 393.298 42.5104 394.088 41.7534C394.878 41.0129 395.832 40.4205 396.918 39.9926C398.003 39.5647 399.188 39.3508 400.422 39.3508C401.919 39.3508 403.185 39.6799 404.222 40.3546C405.258 41.0293 406.064 42.0002 406.607 43.2674C407.15 44.5345 407.43 46.1143 407.43 48.0067V71.2262H416.675V47.7105C416.675 46.5092 416.905 45.4231 417.35 44.4193C417.794 43.4319 418.419 42.5433 419.209 41.7699C419.998 40.9964 420.969 40.404 422.087 39.9761C423.206 39.5483 424.391 39.3344 425.624 39.3344C427.22 39.3344 428.553 39.647 429.622 40.2724C430.691 40.8977 431.481 41.8521 432.007 43.1522C432.534 44.4522 432.797 46.0813 432.797 48.0725V71.2098H442.042V47.4801C442.042 43.8927 441.549 40.8812 440.545 38.4457C439.542 36.0102 438.061 34.1836 436.071 32.9988L436.12 33.0152Z"
          fill="#0F084B"
        />
        <path
          d="M486.636 36.5696C484.991 34.8582 483.05 33.4923 480.813 32.5049C478.575 31.5176 476.157 31.0074 473.574 31.0074C470.646 31.0074 467.948 31.5176 465.48 32.5379C463.013 33.5581 460.907 35.0063 459.13 36.8658C457.37 38.7253 455.988 40.9305 454.985 43.4647C453.981 45.9989 453.488 48.8129 453.488 51.9067C453.488 55.7903 454.376 59.2296 456.136 62.2411C457.896 65.2525 460.364 67.6222 463.523 69.3665C466.681 71.1109 470.317 71.9831 474.446 71.9831C476.289 71.9831 478.148 71.7362 480.039 71.2425C481.931 70.7489 483.724 70.0577 485.452 69.1855C487.163 68.3134 488.693 67.2602 490.042 66.0095L485.485 59.6575C483.691 61.2043 482.03 62.274 480.484 62.8664C478.937 63.4588 477.243 63.755 475.417 63.755C472.785 63.755 470.481 63.2449 468.507 62.2246C466.55 61.2043 465.02 59.7562 463.95 57.8638C463.227 56.5966 462.782 55.165 462.536 53.6016H491.522L491.604 50.3927C491.654 47.6116 491.242 45.028 490.371 42.6748C489.499 40.3051 488.248 38.2646 486.603 36.5531L486.636 36.5696ZM467.421 40.552C469.083 39.614 471.139 39.1367 473.574 39.1367C475.17 39.1367 476.618 39.4659 477.934 40.1406C479.25 40.8153 480.336 41.7368 481.174 42.9052C482.013 44.0736 482.523 45.4394 482.672 46.9698V47.3483H462.749C462.963 46.3445 463.243 45.4394 463.638 44.6166C464.477 42.8558 465.744 41.49 467.405 40.552H467.421Z"
          fill="#0F084B"
        />
        <path
          d="M532.554 32.8682C530.547 31.6669 528.047 31.0745 525.053 31.0745C522.963 31.0745 520.923 31.5188 518.933 32.4239C516.942 33.3125 515.231 34.4974 513.784 35.9619C513.307 36.4392 512.912 36.9493 512.55 37.4594L512.451 31.8973H503.502V71.2273H512.682V47.4977C512.682 46.2964 512.912 45.2103 513.389 44.2064C513.866 43.2191 514.508 42.3304 515.33 41.557C516.153 40.7836 517.123 40.1912 518.242 39.7633C519.361 39.3354 520.594 39.1215 521.927 39.1215C523.572 39.0721 524.954 39.3519 526.106 39.9772C527.257 40.6026 528.096 41.5735 528.639 42.89C529.182 44.2064 529.462 45.8685 529.462 47.8597V71.2273H538.641V47.2015C538.641 43.614 538.131 40.6355 537.111 38.2493C536.091 35.8632 534.578 34.0695 532.554 32.8682Z"
          fill="#0F084B"
        />
        <path
          d="M566.098 21.892H556.919V31.8973H549.154V40.4051H556.919V71.2273H566.098V40.4051H574.669V31.8973H566.098V21.892Z"
          fill="#0F084B"
        />
        <path
          d="M613.03 37.4428C612.274 36.3074 611.352 35.2706 610.267 34.3655C609.016 33.3124 607.585 32.506 605.94 31.9465C604.295 31.3705 602.485 31.0908 600.495 31.0908C597.056 31.0908 593.98 31.963 591.249 33.7073C588.518 35.4516 586.347 37.8542 584.751 40.948C583.155 44.0417 582.366 47.5469 582.366 51.4634C582.366 55.3799 583.155 58.9015 584.751 61.9788C586.347 65.0726 588.453 67.5081 591.085 69.2853C593.717 71.0626 596.662 71.9677 599.886 71.9677C601.778 71.9677 603.555 71.6715 605.216 71.079C606.878 70.4866 608.391 69.6967 609.773 68.7258C611.023 67.8372 612.076 66.8334 612.948 65.6979V71.2271H622.276V31.8971H613.03V37.4428ZM608.095 62.275C606.45 63.3282 604.558 63.8384 602.419 63.8384C600.281 63.8384 598.405 63.3118 596.793 62.275C595.181 61.2383 593.914 59.7737 593.026 57.9142C592.138 56.0546 591.677 53.8989 591.677 51.4634C591.677 49.0279 592.121 46.9544 593.026 45.1114C593.914 43.2683 595.181 41.8201 596.793 40.7834C598.405 39.7467 600.281 39.2201 602.419 39.2201C604.558 39.2201 606.45 39.7467 608.095 40.7834C609.74 41.8201 611.023 43.2683 611.945 45.1114C612.866 46.9544 613.326 49.0608 613.326 51.4634C613.326 53.866 612.866 56.0546 611.945 57.9142C611.023 59.7737 609.74 61.2383 608.095 62.275Z"
          fill="#0F084B"
        />
        <path d="M646 16H636.755V71.2267H646V16Z" fill="#0F084B" />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
