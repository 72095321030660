import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  TextareaAutosize,
  FormControl,
  FormHelperText,
  Box,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Iconify from '../iconify';
import WundaButton from './WundaButton';

const WundaForm = ({
  fields,
  onSubmit,
  onCancel,
  defaultValues,
  submitLabel = 'Submit',
  title = '',
  subTitle = '',
  ...muiProps
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmitForm = (data) => {
    // Filter out fields with default values of empty string or null
    const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== '' && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});

    // Call the provided onSubmit function with the filtered data
    onSubmit(filteredData);
  };

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const EmailField = ({ field, fieldProps }) => {
    const [email, setEmail] = React.useState(fieldProps.defaultValue || '');
    const [isEmailValid, setIsEmailValid] = React.useState(true);

    const handleEmailChange = (event) => {
      const emailInput = event.target.value;
      setEmail(emailInput);
      setIsEmailValid(validateEmail(emailInput));
      field.onChange(event);
    };

    const error = !isEmailValid || !!errors[fieldProps.name];
    const helperText = !isEmailValid ? 'Please enter a valid email' : errors[fieldProps.name]?.message;

    return (
      <TextField
        {...field}
        {...fieldProps}
        fullWidth
        value={email}
        onChange={handleEmailChange}
        error={error}
        helperText={helperText}
      />
    );
  };

  EmailField.propTypes = {
    field: PropTypes.object.isRequired,
    fieldProps: PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      rules: PropTypes.object,
      xs: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string,
        })
      ),
      label: PropTypes.string,
      defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  };

  return (
    <Box {...muiProps}>
      {title && (
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography variant="subtitle2" gutterBottom>
          {subTitle}
        </Typography>
      )}
      <Box mt={2}>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            {fields.map((fieldProps, index) => (
              <Grid item xs={fieldProps.xs || 12} key={index}>
                <Controller
                  control={control}
                  name={fieldProps.name}
                  rules={fieldProps.rules}
                  render={({ field }) => {
                    switch (fieldProps.type) {
                      case 'text':
                        return (
                          <TextField
                            {...field}
                            {...fieldProps} // Spread all fieldProps into TextField
                            fullWidth
                            error={!!errors[fieldProps.name]}
                            helperText={errors[fieldProps.name]?.message}
                          />
                        );
                      case 'email':
                        return <EmailField field={field} fieldProps={fieldProps} />;
                      case 'textarea':
                        return (
                          <TextareaAutosize
                            {...field}
                            {...fieldProps} // Spread all fieldProps into TextareaAutosize
                            aria-label={fieldProps.label}
                            minRows={3}
                            placeholder={fieldProps.label}
                          />
                        );
                      case 'radio':
                        return (
                          <RadioGroup {...field} {...fieldProps}>
                            {' '}
                            {fieldProps.options.map((option, index) => (
                              <FormControlLabel
                                key={index}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                              />
                            ))}
                          </RadioGroup>
                        );
                      case 'select':
                        return (
                          <Select {...field} {...fieldProps}>
                            {' '}
                            {fieldProps.options.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      case 'date':
                        return (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                              control={control}
                              name={fieldProps.name}
                              defaultValue={null}
                              rules={fieldProps.rules}
                              render={({ field, fieldState: { error } }) => (
                                <FormControl fullWidth error={!!error}>
                                  <DatePicker
                                    {...fieldProps} // Spread all fieldProps into DatePicker
                                    label={fieldProps.label}
                                    // eslint-disable-next-line react/prop-types
                                    value={field.value ? dayjs(field.value) : null}
                                    onChange={(newValue) => {
                                      // eslint-disable-next-line react/prop-types
                                      field.onChange(newValue ? newValue.toISOString() : null);
                                    }}
                                    renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => dayComponent}
                                    slots={{
                                      OpenPickerIcon: () => <Iconify icon="ic:round-date-range" />,
                                    }}
                                    slotProps={{
                                      actionBar: {
                                        actions: ['clear', 'today'],
                                      },
                                    }}
                                  />
                                  {error && <FormHelperText>{error.message}</FormHelperText>}
                                </FormControl>
                              )}
                            />
                          </LocalizationProvider>
                        );
                      default:
                        return null;
                    }
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12} sx={{ mt: 2, display: 'flex', justifyContent: onCancel ? 'flex-end' : 'center' }}>
              {onCancel && (
                <WundaButton variant="outlined" color="secondary" onClick={onCancel} sx={{ mr: 2 }}>
                  Cancel
                </WundaButton>
              )}
              <WundaButton type="submit" variant="contained" color="primary">
                {submitLabel}
              </WundaButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

WundaForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      rules: PropTypes.object,
      value: PropTypes.any,
      xs: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
          label: PropTypes.string,
        })
      ),
      label: PropTypes.string,
      defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      onChange: PropTypes.func,
    }).isRequired
  ).isRequired,
  fieldProps: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    rules: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    xs: PropTypes.number,
    onChange: PropTypes.func,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object,
  submitLabel: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  muiProps: PropTypes.object,
};

export default WundaForm;
