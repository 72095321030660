import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import {
  Container,
  Paper,
  Typography,
  Chip,
  Stack,
  Divider,
  Modal,
  Box,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Alert,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import EventIcon from '@mui/icons-material/Event';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import DescriptionIcon from '@mui/icons-material/Description';
import InsightsIcon from '@mui/icons-material/Insights';
import { analytics } from '../firebase/firebase';
import { AuthContext } from '../providers/AuthProvider';
import InsightsReportCard from '../components/wundamental/InsightsReportCard';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import Iconify from '../components/iconify';
import PromptDialog from '../components/dialog/PromptDialog';
import ErrorMessage from '../components/alert/ErrorMessage';
import WundaTabPanel from '../components/wundamental/WundaTabPanel';
import GenericLoader from '../components/loader/GenericLoader';
import WundaButton from '../components/wundamental/WundaButton';
import WundaSpeakerRatio from '../components/wundamental/WundaSpeakerRatio';
import WundaIconButton from '../components/wundamental/WundaIconButton';
import SessionEditForm from '../sections/@dashboard/session/SessionEditForm';
import { apiRequest } from '../api/api';
import languages from '../config/languages.json';

const SessionDetailPage = () => {
  const { sessionId } = useParams();
  const { currentUser, setAdvancedBalanceMode, isFeatureEnabled } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [openSessionDeleteDialog, setOpenSessionDeleteDialog] = useState(false);
  const [openRecordingDeleteDialog, setOpenRecordingDeleteDialog] = useState(false);
  const [openTranscriptDeleteDialog, setOpenTranscriptDeleteDialog] = useState(false);
  const [openGenerateInsightsDialog, setOpenGenerateInsightsDialog] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [sessionData, setSessionData] = useState({});
  const [openCard, setOpenCard] = useState('insights');
  const [showListenCard, setShowListenCard] = useState(false);
  const [showTranscriptCard, setShowTranscriptCard] = useState(false);
  const [showInsightsCard, setShowInsightsCard] = useState(true);
  const [recordingFileUrl, setRecordingFileUrl] = useState(null);
  const [tabPanelValue, setTabPanelValue] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);

  const maxInsightsReports = 3;
  const language = languages.find((lang) => lang.value === sessionData.language);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '50%', // If screen size is small, width is 100%, else it's 50%.
    bgcolor: 'background.paper',
    border: '2px solid #365a47',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };

  const handleOpenDownloadMenu = (event) => {
    setDownloadAnchorEl(event.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setDownloadAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabPanelValue(newValue);
  };

  const handleThemeChange = (event, theme) => {
    setSelectedTheme(theme);
  };

  const handleTemplateTabClick = (template) => {
    setSelectedTemplate(template);
    logEvent(analytics, 'view_template_insights_click', {
      sessionId: sessionData._id,
      templateId: template._id,
    });
  };

  const handleThemeButtonClick = (theme) => {
    setTabPanelValue(0);
    setSelectedTheme(theme);
    if (theme.templates && theme.templates.length > 0) {
      if (selectedTemplate) {
        const existingTemplate = theme.templates.find((template) => template._id === selectedTemplate._id);
        if (existingTemplate) {
          setSelectedTemplate(existingTemplate);
        } else {
          setSelectedTemplate(theme.templates[0]);
        }
      } else {
        setSelectedTemplate(theme.templates[0]);
      }
    }
    logEvent(analytics, 'view_theme_insights_click', {
      sessionId: sessionData._id,
      themeId: theme._id,
    });
  };

  useEffect(() => {
    setAdvancedBalanceMode(false);
  }, [setAdvancedBalanceMode]);

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  const handleInsightReportCardActionSuccess = (action, message) => {
    fetchSessionData();
    showSuccess(message);
  };

  const handleInsightReportCardActionFailure = (action, message) => {
    setErrorMessage(message);
  };

  const handleOpenListenCard = () => {
    setOpenCard('recording');
    logEvent(analytics, 'session_detail_view_audio_click', {
      sessionId: sessionData._id,
    });
    loadAudioFile();
    setShowListenCard(true);
  };

  const loadAudioFile = async () => {
    try {
      const response = await apiRequest('GET', `/audio/download/${sessionData.audioFile._id}`);
      setRecordingFileUrl(response.url);
    } catch (error) {
      console.error('Error getting signed URL:', error);
    }
  };

  const handleOpenTranscriptCard = () => {
    setOpenCard('transcript');
    logEvent(analytics, 'session_detail_view_transcript_click', {
      sessionId: sessionData._id,
    });
    setShowTranscriptCard(true);
  };

  const handleOpenInsightsCard = () => {
    setOpenCard('insights');
    logEvent(analytics, 'session_detail_view_insights_click', {
      sessionId: sessionData._id,
    });
    fetchSessionData();
    setShowInsightsCard(true);
  };

  const handleOpenEdit = () => {
    setShowEditModal(true);
  };

  const handleCloseEdit = async () => {
    setShowEditModal(false);
    await fetchSessionData();
  };

  const handleEditSessionSuccess = async () => {
    // Handle the success case here
    // You might want to fetch the updated data and close the modal
    handleCloseEdit();
    await fetchSessionData();
  };

  const handleDeleteSessionConfirmDialog = async () => {
    setIsLoading(true);
    try {
      await apiRequest('DELETE', `session/${sessionId}`);
      logEvent(analytics, 'delete_session', {
        sessionId,
        method: 'session_detail',
      });
      let countdown = 5;
      const intervalId = setInterval(() => {
        countdown -= 1;
        showSuccess(`Successfully deleted a session! Redirecting to main page in ${countdown} seconds...`);
        if (countdown <= 0) {
          clearInterval(intervalId);
          navigate('/home/sessions');
        }
      }, 1000);
    } catch (error) {
      console.error(`Error deleting session ${sessionId}`, error);
      setErrorMessage(`Error deleting a session! ${error.message}`);
    } finally {
      setOpenSessionDeleteDialog(false);
      setIsLoading(false);
    }
  };

  const handleDeleteRecordingConfirmDialog = async () => {
    setOpenRecordingDeleteDialog(false);
    const deleteItemId = sessionData.audioFile._id;
    if (!deleteItemId) {
      return;
    }
    setIsLoading(true);
    try {
      await apiRequest('DELETE', `audio/${deleteItemId}`);
      showSuccess('Successfully deleted a recording!');
      logEvent(analytics, 'delete_audio', {
        audioId: deleteItemId,
        method: 'session_detail',
      });
      fetchSessionData();
    } catch (error) {
      console.error(`Error deleting recording ${deleteItemId}`, error);
      setErrorMessage(`Error deleting a recording! ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTranscriptConfirmDialog = async () => {
    setOpenTranscriptDeleteDialog(false);
    const transcriptId = sessionData.transcript._id;
    if (!transcriptId) {
      return;
    }
    setIsLoading(true);
    try {
      await apiRequest('DELETE', `transcript/${transcriptId}`);
      showSuccess('Successfully deleted a transcript!');
      logEvent(analytics, 'delete_transcript', {
        transcriptId,
        method: 'session_detail',
      });
      fetchSessionData();
    } catch (error) {
      console.error(`Error deleting transcript ${transcriptId}`, error);
      setErrorMessage(`Error deleting a transcript! ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadTranscript = async (format) => {
    try {
      handleCloseDownloadMenu();
      handleCloseMenu();
      const { _id, name } = sessionData.transcript;
      const fileExtension = format;

      const response = await apiRequest('GET', `/transcript/download/${_id}?format=${format}`, {
        responseType: 'blob',
      });
      const downloadUrl = URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `Transcript - ${name}.${fileExtension}`;
      document.body.appendChild(link);
      logEvent(analytics, 'download_transcript', {
        transcriptId: _id,
        method: 'session_detail',
      });
      link.click();
      link.remove();
      URL.revokeObjectURL(downloadUrl);
      showSuccess(`Successfully downloaded the transcript!`);
    } catch (error) {
      setErrorMessage(`Error downloading a transcript! ${error.response?.data?.message || error.message}`);
    }
  };

  const handleGenerateInsightsReport = async () => {
    try {
      setOpenGenerateInsightsDialog(false);
      handleCloseMenu();
      setIsLoading(true);
      if (!sessionData.transcript) {
        throw new Error('Transcript not found!');
      }
      const reportData = {
        userId: currentUser.DBuser._id,
        transcriptId: sessionData.transcript._id,
        sessionId: sessionData._id,
        reportType: 'MANUAL',
        targetedAt: selectedTemplate.targetedAt,
        sessionDate: sessionData.scheduledStartAt || sessionData.createdAt,
        sessionName: sessionData.name,
        sessionDuration: sessionData.transcript.sessionDuration || null,
        nurturerName: sessionData.nurturerName || null,
        flourisherName: sessionData.flourisherName || null,
        recipientEmail: null,
        sendAsEmail: false,
        tags: sessionData.tags,
      };
      if (selectedTemplate) {
        reportData.templateId = selectedTemplate._id;
      }
      await apiRequest('POST', `/insights-report`, {
        data: reportData,
      });

      logEvent(analytics, `generate_${selectedTemplate.targetedAt}_insights`, {
        sessionId: sessionData._id,
        templateId: selectedTemplate._id,
        method: 'session_detail',
      });
      fetchSessionData();
      showSuccess('Successfully started generation of a new report!');
    } catch (error) {
      console.error(error);
      setErrorMessage(`Error generating a report! ${error.response?.data?.errorDetail || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateInsightsCancel = () => {
    setOpenGenerateInsightsDialog(false);
    handleCloseMenu();
  };

  const handleGenerateInsightsConfirm = () => {
    setOpenGenerateInsightsDialog(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    // do nothing for now as we don't use menu :)
  };

  const handleDeleteSessionConfirm = () => {
    setOpenSessionDeleteDialog(true);
    handleCloseMenu();
  };

  const handleDeleteSessionCancel = () => {
    setOpenSessionDeleteDialog(false);
    handleCloseMenu();
  };

  const handleDeleteTranscriptConfirm = () => {
    setOpenTranscriptDeleteDialog(true);
    handleCloseMenu();
  };

  const handleDeleteTranscriptCancel = () => {
    setOpenTranscriptDeleteDialog(false);
    handleCloseMenu();
  };

  const handleDeleteRecordingConfirm = () => {
    setOpenRecordingDeleteDialog(true);
    handleCloseMenu();
  };

  const handleDeleteRecordingCancel = () => {
    setOpenRecordingDeleteDialog(false);
    handleCloseMenu();
  };

  const fetchSessionData = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const sessionData = await apiRequest('GET', `/session/${sessionId}`);
      setSessionData(sessionData);
    } catch (error) {
      console.error('Failed to fetch the session data:', error);
      setErrorMessage(`Failed to fetch sessions. ${error.response?.data?.message || error}`);
    } finally {
      setIsLoading(false);
    }
  }, [sessionId]);

  useEffect(() => {
    if (!selectedTheme && sessionData && Object.keys(sessionData).length !== 0) {
      setSelectedTheme(sessionData.theme);
      if (sessionData.theme.templates && sessionData.theme.templates.length > 0) {
        setSelectedTemplate(sessionData.theme.templates[0]);
      }
    }
  }, [selectedTemplate, selectedTheme, sessionData]);

  useEffect(() => {
    if (sessionId) {
      fetchSessionData();
    }
  }, [fetchSessionData, sessionId]);

  if (isLoading) {
    return <GenericLoader />;
  }

  // Ensure sessionData is an object before proceeding
  const isSessionDataEmpty = sessionData ? Object.keys(sessionData).length === 0 : true;

  if (isSessionDataEmpty && errorMessage) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <ErrorOutlineIcon color="error" style={{ fontSize: 60 }} />
        <Typography variant="h6" color="error">
          {`An error occurred. ${errorMessage || errorMessage.message}`}
        </Typography>
        {/* Ensure errorMessage.message is accessed only if errorMessage is an object */}
        <Typography>{errorMessage && errorMessage.message ? errorMessage.message : ''}</Typography>
      </Box>
    );
  }

  if (isSessionDataEmpty) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <WarningIcon color="warning" />
        <Typography variant="h6">No session data available</Typography>
      </Box>
    );
  }

  // Define menuItems for Audio recording card
  const recordingMenuItems = [
    {
      label: !sessionData.demoForAll ? 'Delete' : 'Delete (Disabled for demo)',
      onClick: sessionData.demoForAll ? null : handleDeleteRecordingConfirm,
      iconifyIcon: 'eva:trash-2-outline',
      disabled: sessionData.demoForAll,
    },
  ];

  // Define menuItems for Transcript card
  const transcriptMenuItems = [
    ...((sessionData.insightsReports.filter((report) => report.targetedAt !== 'nurturer').length === 0 &&
      sessionData.transcript) ||
    (isFeatureEnabled('advanced') &&
      sessionData.transcript &&
      sessionData.insightsReports &&
      sessionData.insightsReports.filter((report) => report.targetedAt !== 'nurturer').length < maxInsightsReports)
      ? [
          {
            label: 'Generate insights for coachee',
            onClick: handleGenerateInsightsConfirm,
            iconifyIcon: 'streamline:ai-technology-spark',
          },
        ]
      : []),
    { label: 'Download', onClick: handleOpenDownloadMenu, iconifyIcon: 'eva:download-outline' },
    {
      label: !sessionData.demoForAll ? 'Delete' : 'Delete (Disabled for demo)',
      onClick: sessionData.demoForAll ? null : handleDeleteTranscriptConfirm,
      iconifyIcon: 'eva:trash-2-outline',
      disabled: sessionData.demoForAll,
    },
  ];

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Container component="main">
      <WundaButton startIcon={<ArrowBackIcon />} sx={{ m: 1 }} onClick={() => navigate('/home/sessions')}>
        All Sessions
      </WundaButton>
      <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
      <Paper elevation={3} sx={{ p: 2, my: 3 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h5" gutterBottom className="fs-mask">
            {sessionData.name}
          </Typography>
          <Stack direction="row" spacing={0.5}>
            <WundaIconButton
              icon="eva:edit-outline"
              onClick={handleOpenEdit}
              tooltip={sessionData.demoForAll ? 'Edit (Disabled for demo)' : 'Edit session'}
              tooltipPlacement="top"
              disabled={sessionData.demoForAll}
            />
            <WundaIconButton
              icon="eva:trash-2-outline"
              onClick={handleDeleteSessionConfirm}
              tooltip={sessionData.demoForAll ? 'Delete the demo session' : 'Delete session'}
              tooltipPlacement="top"
            />
          </Stack>
        </Stack>
        <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column', alignItems: 'flex-start' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <EventIcon sx={{ fontSize: '1rem' }} />
              <Tooltip title="Session date">
                <Typography variant="body2" color="text.secondary">
                  {sessionData.sessionDate
                    ? new Date(sessionData.sessionDate).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })
                    : ''}
                </Typography>
              </Tooltip>
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Tooltip title="Session duration">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTimeIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      {sessionData.duration} mins
                    </Typography>
                  </Box>
                </Tooltip>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Tooltip title="Transcription language">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon="mdi-earth" sx={{ fontSize: '1rem', mr: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      {language ? (isMobile ? language.value.toUpperCase() : language.label) : ''}
                    </Typography>
                  </Box>
                </Tooltip>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  {sessionData &&
                    sessionData.tags &&
                    sessionData.tags.map((tag, index) => (
                      <Chip key={index} label={tag} variant="outlined" sx={{ mr: 1, mb: 0, py: 0 }} />
                    ))}
                </Box>
              </Box>
            </Box>
          </Stack>

          {/* Render speaker role ratio component */}
          {sessionData && Object.keys(sessionData).length > 0 && <WundaSpeakerRatio data={sessionData} />}
        </Box>
        <Divider />
        <Stack direction="row" spacing={2} sx={{ mt: 2 }} justifyContent="center">
          <Card
            sx={{
              flex: 1,
              mt: 1,
              backgroundColor: openCard === 'recording' ? theme.palette.secondary.main : theme.palette.background.paper,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <CardActionArea
              onClick={handleOpenListenCard}
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box display="flex" alignItems="center">
                  <VolumeUpIcon
                    sx={{
                      fontSize: 60,
                      color: (theme) => (sessionData.audioFile ? 'inherit' : theme.palette.grey[500]),
                    }}
                  />
                  {!isMobile && (
                    <Typography
                      variant="h6"
                      sx={{ ml: 2, color: (theme) => (sessionData.audioFile ? 'inherit' : theme.palette.grey[500]) }}
                    >
                      Audio
                    </Typography>
                  )}
                </Box>
                <Typography variant="caption">
                  {sessionData.audioFile ? 'Listen to the audio of the session' : 'Audio recording not available'}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card
            sx={{
              flex: 1,
              mt: 1,
              backgroundColor:
                openCard === 'transcript' ? theme.palette.secondary.main : theme.palette.background.paper,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <CardActionArea
              onClick={handleOpenTranscriptCard}
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box display="flex" alignItems="center">
                  <DescriptionIcon
                    sx={{
                      fontSize: 60,
                      color: (theme) => (sessionData.transcript ? 'inherit' : theme.palette.grey[500]),
                    }}
                  />
                  {!isMobile && (
                    <Typography
                      variant="h6"
                      sx={{ ml: 2, color: (theme) => (sessionData.transcript ? 'inherit' : theme.palette.grey[500]) }}
                    >
                      Transcript
                    </Typography>
                  )}
                </Box>
                <Typography variant="caption">
                  {sessionData.transcript ? 'Read the transcription of the session' : 'Transcript not available'}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card
            sx={{
              flex: 1,
              mt: 1,
              backgroundColor: openCard === 'insights' ? theme.palette.secondary.main : theme.palette.background.paper,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <CardActionArea
              onClick={handleOpenInsightsCard}
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box display="flex" alignItems="center">
                  <InsightsIcon
                    sx={{
                      fontSize: 60,
                      color: (theme) => (sessionData.insightsReports ? 'inherit' : theme.palette.grey[500]),
                    }}
                  />
                  {!isMobile && (
                    <Typography
                      variant="h6"
                      sx={{
                        ml: 2,
                        color: (theme) => (sessionData.insightsReports ? 'inherit' : theme.palette.grey[500]),
                      }}
                    >
                      Insights
                    </Typography>
                  )}
                </Box>
                <Typography variant="caption">
                  {sessionData.insightsReports ? 'Grow from AI insights of the session' : 'Insights not available'}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Stack>
      </Paper>
      <Paper>
        {showListenCard && openCard === 'recording' && (
          <Card sx={{ mb: 2 }}>
            {sessionData.audioFile ? (
              <>
                <CardHeader
                  avatar={
                    <VolumeUpIcon
                      sx={{
                        fontSize: 40,
                        color: (theme) => (sessionData.audioFile ? 'inherit' : theme.palette.grey[500]),
                      }}
                    />
                  }
                  action={
                    <Box display="flex" justifyContent="flex-end" width="100%">
                      {recordingMenuItems.map((item, index) => (
                        <WundaIconButton
                          key={index}
                          onClick={item.onClick}
                          tooltip={item.label}
                          size="small"
                          icon={item.iconifyIcon}
                          disabled={item.disabled}
                        />
                      ))}
                    </Box>
                  }
                  title="Listen to the audio recording"
                  subheader={sessionData.audioFile.originalFileName}
                />
                <CardContent>
                  <Box
                    sx={{
                      overflow: 'hidden', // Prevents content from spilling outside the container
                      width: '100%', // Sets width to fill the parent container
                      position: 'relative', // For absolute positioning of the iframe
                    }}
                  >
                    <iframe src={recordingFileUrl} style={{ height: '100%', width: '100%' }} title="Audio Player" />
                  </Box>
                </CardContent>
              </>
            ) : (
              <Alert severity="warning">Audio recording has been deleted</Alert>
            )}
          </Card>
        )}
        {showTranscriptCard && openCard === 'transcript' && (
          <Card sx={{ mb: 2 }}>
            {sessionData.transcript ? (
              <>
                <CardHeader
                  avatar={
                    <DescriptionIcon
                      sx={{
                        fontSize: 40,
                        color: (theme) => (sessionData.transcript ? 'inherit' : theme.palette.grey[500]),
                      }}
                    />
                  }
                  action={
                    <Box display="flex" justifyContent="flex-end" width="100%">
                      {transcriptMenuItems.map((item, index) => (
                        <WundaIconButton
                          key={index}
                          onClick={item.onClick}
                          tooltip={item.label}
                          size="small"
                          icon={item.iconifyIcon}
                          disabled={item.disabled}
                        />
                      ))}
                      <Menu
                        anchorEl={downloadAnchorEl}
                        open={Boolean(downloadAnchorEl)}
                        onClose={handleCloseDownloadMenu}
                      >
                        <MenuItem onClick={() => handleDownloadTranscript('txt')}>
                          <Typography variant="body2">Download TXT</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => handleDownloadTranscript('srt')}>
                          <Typography variant="body2">Download SRT</Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                  }
                  title="Transcript"
                />
                <CardContent>
                  <Alert severity="info" sx={{ mb: 2, bgcolor: theme.palette.background.GreyBG02 }}>
                    Please note that this transcription has been generated automatically and may not be 100% accurate.
                    Transcription quality depends heavily on audio quality and speaker clarity.
                  </Alert>
                  {sessionData.transcript?.roleDialogue.map((dialogue, index) => (
                    <Box key={index} sx={{ mb: 2 }} className="fs-mask">
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="subtitle1" color="text.primary">
                          {dialogue.speaker.charAt(0).toUpperCase() + dialogue.speaker.slice(1)}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                          ({dialogue.startTime ? new Date(dialogue.startTime * 1000).toISOString().slice(11, 19) : ''} -
                          {dialogue.endTime ? new Date(dialogue.endTime * 1000).toISOString().slice(11, 19) : ''})
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        {dialogue.text}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              </>
            ) : (
              <Alert severity="warning">Transcript has been deleted</Alert>
            )}
          </Card>
        )}
        {showInsightsCard && openCard === 'insights' && (
          <>
            {sessionData.allowedThemes &&
              sessionData.allowedThemes.filter((theme) => theme.templates && theme.templates.length > 0).length > 1 && (
                <Box width={1}>
                  <ToggleButtonGroup
                    value={
                      selectedTheme
                        ? sessionData.allowedThemes.findIndex((theme) => theme._id === selectedTheme._id)
                        : 0
                    }
                    exclusive
                    onChange={(event, newThemeIndex) =>
                      handleThemeChange(event, sessionData.allowedThemes[newThemeIndex])
                    }
                    aria-label="theme selection"
                    fullWidth
                  >
                    {sessionData.allowedThemes
                      .filter((theme) => theme.templates && theme.templates.length > 0)
                      .map((themeObject, index) => (
                        <ToggleButton
                          key={index}
                          onClick={() => {
                            handleThemeButtonClick(themeObject);
                          }}
                          value={index}
                          aria-label={themeObject.name}
                          style={{
                            color:
                              index ===
                              (selectedTheme
                                ? sessionData.allowedThemes.findIndex((theme) => theme._id === selectedTheme._id)
                                : 0)
                                ? theme.palette.secondary.contrastText
                                : 'inherit',
                            backgroundColor:
                              index ===
                              (selectedTheme
                                ? sessionData.allowedThemes.findIndex((theme) => theme._id === selectedTheme._id)
                                : 0)
                                ? theme.palette.secondary.main
                                : 'transparent',
                          }}
                        >
                          {themeObject.name}
                        </ToggleButton>
                      ))}
                  </ToggleButtonGroup>
                </Box>
              )}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabPanelValue} onChange={handleTabChange} aria-label="insights tabs" centered>
                {sessionData.allowedThemes &&
                  selectedTheme &&
                  sessionData.allowedThemes
                    .filter((theme) => theme._id === selectedTheme._id && theme.templates && theme.templates.length > 0)
                    .flatMap((theme, themeIndex) =>
                      theme.templates.map((template, templateIndex) => (
                        <Tab
                          key={`${themeIndex}-${templateIndex}`}
                          icon={<Iconify icon={template.icon} width={24} height={24} />}
                          label={template.labelShort}
                          onClick={() => handleTemplateTabClick(template)}
                          {...a11yProps(themeIndex)}
                        />
                      ))
                    )}
              </Tabs>
            </Box>
            {sessionData.allowedThemes &&
              selectedTheme &&
              sessionData.allowedThemes
                .filter((theme) => theme._id === selectedTheme._id)
                .flatMap((theme, themeIndex) =>
                  theme.templates.map((template, templateIndex) => {
                    const tabIndex = themeIndex * theme.templates.length + templateIndex;
                    return (
                      <WundaTabPanel key={tabIndex} value={tabPanelValue} index={tabIndex}>
                        <Card sx={{ mb: 2 }}>
                          <CardHeader
                            avatar={
                              <InsightsIcon
                                sx={{
                                  fontSize: 40,
                                  color: (theme) => (sessionData.transcript ? 'inherit' : theme.palette.grey[500]),
                                }}
                              />
                            }
                            action={
                              <>
                                {isFeatureEnabled('advanced') &&
                                sessionData.transcript &&
                                sessionData.insightsReports &&
                                sessionData.insightsReports.length < maxInsightsReports ? (
                                  isMobile ? (
                                    <WundaIconButton
                                      icon="streamline:ai-technology-spark"
                                      onClick={handleGenerateInsightsConfirm}
                                      tooltip="Generate new variation of insights"
                                    />
                                  ) : (
                                    <WundaButton
                                      startIcon={<Iconify icon={'streamline:ai-technology-spark'} />}
                                      onClick={handleGenerateInsightsConfirm}
                                      variant="outlined"
                                      tooltip="Generate new variation of insights"
                                    >
                                      New Insights
                                    </WundaButton>
                                  )
                                ) : null}
                              </>
                            }
                            title={template.label}
                          />
                          <CardContent sx={{ py: 1, px: 0 }}>
                            {sessionData.insightsReports &&
                            sessionData.insightsReports.filter(
                              (report) => !report.templateID || report.templateID === template._id
                            ).length > 0 ? (
                              sessionData.insightsReports
                                .filter((report) => !report.templateID || report.templateID === template._id)
                                .map((report, index) => {
                                  const matchingTemplate = selectedTheme.templates.find(
                                    (template) => template._id === report.templateID
                                  );
                                  return (
                                    <InsightsReportCard
                                      key={template._id + index}
                                      index={index}
                                      report={report}
                                      roleDialogue={
                                        sessionData.transcript && sessionData.transcript.roleDialogue
                                          ? sessionData.transcript.roleDialogue
                                          : []
                                      }
                                      template={matchingTemplate}
                                      onSuccess={handleInsightReportCardActionSuccess}
                                      onFailure={handleInsightReportCardActionFailure}
                                      onDataRefresh={fetchSessionData}
                                      demoForAll={sessionData.demoForAll}
                                    />
                                  );
                                })
                            ) : (
                              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <Iconify icon="mdi:rocket-launch" width={50} height={50} />
                                <Box maxWidth="80%">
                                  <Typography variant="h5" color="text.secondary" align="center" gutterBottom>
                                    {template.ctaTitle}
                                  </Typography>
                                  <Typography variant="body1" color="text.secondary" align="center">
                                    {template.ctaBody}
                                  </Typography>
                                </Box>
                                <WundaButton
                                  sx={{ mt: 2 }}
                                  onClick={sessionData.demoForAll ? null : handleGenerateInsightsConfirm}
                                  startIcon={
                                    <Iconify icon="streamline:ai-science-spark" sx={{ fontSize: '1rem', mr: 0.5 }} />
                                  }
                                  disabled={sessionData.demoForAll || !sessionData.transcript}
                                  tooltip={
                                    sessionData.demoForAll
                                      ? 'Disabled for demo session'
                                      : !sessionData.transcript
                                        ? 'Transcript not available'
                                        : ''
                                  }
                                >
                                  Generate
                                </WundaButton>
                              </Box>
                            )}
                          </CardContent>
                        </Card>
                      </WundaTabPanel>
                    );
                  })
                )}
          </>
        )}
      </Paper>
      <Modal open={showEditModal} onClose={handleCloseEdit}>
        <Box sx={modalStyle}>
          <SessionEditForm
            sessionId={sessionId} // Pass the session data to the form
            onCancel={handleCloseEdit}
            onSuccess={handleEditSessionSuccess}
          />
        </Box>
      </Modal>

      <PromptDialog
        open={openGenerateInsightsDialog}
        title="Confirm Generation"
        message="Are you sure you want to generate a new insights report?"
        onCancel={handleGenerateInsightsCancel}
        onConfirm={handleGenerateInsightsReport}
      />

      <PromptDialog
        open={openSessionDeleteDialog}
        title="Confirm Deletion"
        message={
          sessionData.demoForAll
            ? 'Are you sure you want to remove this demo session from your account?'
            : 'Are you sure you want to delete this session and all associated data stored?'
        }
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onCancel={handleDeleteSessionCancel}
        onConfirm={handleDeleteSessionConfirmDialog}
      />

      <PromptDialog
        open={openRecordingDeleteDialog}
        title="Confirm Deletion"
        message="Are you sure you want to delete this audio recording? You cannot undo this action."
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onCancel={handleDeleteRecordingCancel}
        onConfirm={handleDeleteRecordingConfirmDialog}
      />

      <PromptDialog
        open={openTranscriptDeleteDialog}
        title="Confirm Deletion"
        message="Are you sure you want to delete this transcript? You cannot undo this action."
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onCancel={handleDeleteTranscriptCancel}
        onConfirm={handleDeleteTranscriptConfirmDialog}
      />

      <GenericSnackbar open={snackbar} autoHideDuration={6000} onClose={handleSnackbarClose} />
    </Container>
  );
};

export default SessionDetailPage;
