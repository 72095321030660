import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Stack,
  Divider,
  Modal,
  Alert,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import { FileWithMetadata } from '../../utils/customTypes';
import GenericLoader from '../loader/GenericLoader';
import ErrorMessage from '../alert/ErrorMessage';
import WundaButton from './WundaButton';
import WundaIconButton from './WundaIconButton';
import Iconify from '../iconify/Iconify';

const WundaVoiceRecorder = ({ onUpload, uploadedFile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  let unblock = null;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [chunks, setChunks] = useState([]);
  const [audioURL, setAudioURL] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [wavesurfer, setWavesurfer] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [file, setFile] = useState(FileWithMetadata());

  const waveformRef = useRef();
  const audioFormat = 'webm';
  const audioType = `audio/${audioFormat}; codecs=opus`;
  const outputFileName = `recording.${audioFormat}`;

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = audioURL;
    link.download = outputFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePlayPause = () => {
    if (wavesurfer) {
      wavesurfer.playPause();
    }
  };

  const handleStop = () => {
    if (wavesurfer) {
      wavesurfer.stop();
    }
  };

  const initializeWaveform = useCallback(
    (url) => {
      if (wavesurfer) {
        wavesurfer.destroy();
      }
      if (url && waveformRef.current) {
        fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            } else {
              const audio = new Audio();
              audio.controls = true;
              audio.src = url;

              const ws = WaveSurfer.create({
                container: waveformRef.current,
                autoCenter: true,
                autoScroll: true,
                waveColor: theme.palette.primary.main,
                progressColor: theme.palette.secondary.main,
                plugins: [TimelinePlugin.create({ style: 'color: #000' })],
              });

              ws.load(url);
              setWavesurfer(ws);

              //   audio.onloadedmetadata = () => {
              //     ws.loadBlob(audio);
              //   };

              audio.oncanplay = () => {
                audio.onplay = () => {
                  ws.play();
                };

                audio.onpause = () => {
                  ws.pause();
                };

                audio.onseeking = () => {
                  const { currentTime } = audio;
                  ws.seekTo(currentTime / audio.duration);
                };
              };
            }
          })
          .catch((e) => {
            console.error(`There was a problem with the fetch operation: ${e.message}`);
          });
      }
    },
    [theme.palette.primary.main, theme.palette.secondary.main, wavesurfer]
  );

  useEffect(
    () => () => {
      if (wavesurfer) {
        wavesurfer.destroy();
      }
    },
    [wavesurfer]
  );

  useEffect(() => {
    setLoading(true);

    navigator.mediaDevices
      .getUserMedia({ audio: true }) // Request access to the default microphone
      .then((stream) => {
        const defaultDeviceId = stream.getTracks()[0].getSettings().deviceId; // Get the deviceId of the default microphone
        stream.getTracks().forEach((track) => track.stop()); // Stop the stream

        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            const microphones = devices.filter((device) => device.kind === 'audioinput');
            setDevices(microphones);

            const storedDeviceId = localStorage.getItem('selectedDeviceId');
            let deviceToSelect;

            if (storedDeviceId) {
              deviceToSelect = microphones.find((device) => device.deviceId === storedDeviceId);
            }

            if (!deviceToSelect) {
              deviceToSelect = microphones.find((device) => device.deviceId === defaultDeviceId);
            }

            if (deviceToSelect) {
              setSelectedDevice(deviceToSelect); // Set the default or stored device
            }
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isRecording) {
        event.preventDefault();
        event.returnValue = '';
        alert("Recording in progress! Don't leave the page without stopping the recording.");
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isRecording]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectDevice = (device) => {
    setSelectedDevice(device);
    localStorage.setItem('selectedDeviceId', device.deviceId);
    handleClose();
  };

  const handleClearRecording = () => {
    if (audioURL && window.confirm('Are you sure you want to clear the recording?')) {
      setAudioURL(null);
      setMediaRecorder(null);
      localStorage.removeItem('audioURL');
    }
  };

  const handleStartRecording = () => {
    handleClearRecording();
    setIsModalOpen(true);
    unblock = () => {
      if (window.confirm('Recording in progress! Are you sure you want to leave this page?')) {
        navigate(location.pathname);
      }
    };
    if (!selectedDevice) {
      alert('No microphone device selected.');
      return;
    }

    navigator.mediaDevices
      .getUserMedia({
        audio: { deviceId: { exact: selectedDevice.deviceId } },
      })
      .then((stream) => {
        const audioContext = new AudioContext();
        const source = audioContext.createMediaStreamSource(stream);
        const processor = audioContext.createScriptProcessor(1024, 1, 1);

        source.connect(processor);
        processor.connect(audioContext.destination);

        processor.onaudioprocess = () => {
          // Do something with the data, e.g. convert it to a waveform
        };

        const mediaRecorder = new MediaRecorder(stream);
        setMediaRecorder(mediaRecorder);
        mediaRecorder.start();
        // Start the timer
        const startTime = Date.now();
        setIsRecording(true);

        mediaRecorder.ondataavailable = (e) => {
          setChunks((prevChunks) => {
            const updatedChunks = [...prevChunks, e.data];
            return updatedChunks;
          });
        };

        mediaRecorder.onstop = () => {
          // Stop the timer and calculate the duration
          const endTime = Date.now();
          const duration = Math.round((endTime - startTime) / 1000);
          setChunks((prevChunks) => {
            const blob = new Blob(prevChunks, { type: audioType, duration });
            const url = URL.createObjectURL(blob);
            setAudioURL(url);
            initializeWaveform(url);

            // Create a File object from the Blob
            const file = new File([blob], outputFileName, { type: audioType });
            const fileWithMetadata = FileWithMetadata(file, duration);

            // Set the file state
            setFile(fileWithMetadata);

            return [];
          });
        };
      })
      .catch((error) => console.error('Error accessing selected microphone:', error));
  };

  useEffect(() => {
    if (file) {
      uploadedFile(file);
    }
  }, [file, uploadedFile]);

  const handleStopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      if (unblock) {
        unblock();
      }
    }

    setIsModalOpen(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
        {loading || !selectedDevice ? (
          <GenericLoader />
        ) : (
          <>
            <Card sx={{ minWidth: '80%' }}>
              <CardHeader
                title={
                  <Stack direction="column" alignItems="center" spacing={2}>
                    <Stack direction="row" alignItems="center">
                      {selectedDevice && (
                        <Typography variant="caption">
                          <b>Currently using:</b> {selectedDevice.label}
                        </Typography>
                      )}
                      <IconButton onClick={handleClick}>
                        <SettingsIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                }
              />
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {devices.map((device) => (
                  <MenuItem key={device.deviceId} onClick={() => handleSelectDevice(device)}>
                    <Typography variant="caption">{device.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
              <CardContent
                sx={{
                  p: 1,
                  minHeight: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  {audioURL ? (
                    <WundaButton
                      variant="outlined"
                      color="secondary"
                      sx={{ m: 1 }}
                      onClick={handleClearRecording}
                      startIcon={<Iconify icon="mdi:delete" />}
                    >
                      Clear Recording
                    </WundaButton>
                  ) : (
                    <WundaButton
                      variant={isRecording ? 'outlined' : 'contained'}
                      color="primary"
                      sx={{ m: 1 }}
                      onClick={isRecording ? handleStopRecording : handleStartRecording}
                      startIcon={isRecording ? <Iconify icon="mdi:stop" /> : <Iconify icon="mdi:record" color="red" />}
                    >
                      {isRecording ? 'Recording in progress' : 'Start Recording'}
                    </WundaButton>
                  )}
                  <Box
                    ref={waveformRef}
                    sx={{
                      width: '100%',
                      maxWidth: '90%',
                      minHeight: '100px',
                      visibility: audioURL && waveformRef.current ? 'visible' : 'hidden',
                    }}
                  />
                  <Box id="wave-timeline" />
                  {audioURL && wavesurfer && (
                    <Box>
                      <WundaIconButton icon="mdi:play-pause" size="large" iconSize={30} onClick={handlePlayPause} />
                      <WundaIconButton icon="mdi:stop" size="large" iconSize={30} onClick={handleStop} />
                    </Box>
                  )}
                  <Divider />
                  {audioURL && wavesurfer && (
                    <>
                      <Alert
                        severity="info"
                        sx={{ mb: 2, maxWidth: '600px', bgcolor: theme.palette.background.GreyBG02 }}
                      >
                        <b>
                          {isMobile
                            ? 'Keep this app open on your mobile device! Leaving this page or closing the app will lose the recording! Download or upload the session recording before leaving.'
                            : 'Keep this browser tab open on your device! Leaving this page will lose the recording! Download or upload the session recording before leaving.'}
                        </b>{' '}
                      </Alert>
                      <Box display="flex" justifyContent="center" gap={2}>
                        <WundaButton
                          variant="outlined"
                          color="secondary"
                          sx={{ m: 1 }}
                          onClick={handleDownload}
                          startIcon={<Iconify icon="mdi:download" />}
                        >
                          Download Recording
                        </WundaButton>
                        <WundaButton
                          variant="contained"
                          color="primary"
                          sx={{ m: 1 }}
                          onClick={onUpload}
                          startIcon={<Iconify icon="mdi:upload" />}
                        >
                          Upload Session Audio
                        </WundaButton>
                      </Box>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </>
        )}
      </Box>

      <Modal
        open={isModalOpen}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            display: 'flex', // Add this
            flexDirection: 'column', // Add this
            justifyContent: 'center', // Add this
            alignItems: 'center', // Add this
            borderRadius: '16px', // Add this
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h3"
            component="h2"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
          >
            <Iconify icon="mdi:record" color="red" width={100} height={100} />
            <span>Recording</span>
            <span>in</span>
            <span>progress...</span>
          </Typography>
          <Alert severity="info" sx={{ mb: 2, mt: 2, maxWidth: '600px', bgcolor: theme.palette.background.GreyBG02 }}>
            {isMobile
              ? 'Keep this app active on the foreground on your mobile device! Leaving this page or locking your device will lose the recording!'
              : 'Keep this browser tab open on your device, and do not navigate away from this page! Recorder only works while you are on this page. Leaving this page will lose the recording!'}
          </Alert>
          <Iconify icon={'svg-spinners:bars-scale'} width={100} height={100} color="#D4FF51" sx={{ my: 2 }} />
          <WundaButton
            variant="outlined"
            color="primary"
            sx={{ m: 1 }}
            onClick={handleStopRecording}
            startIcon={<Iconify icon="mdi:stop" />}
          >
            Stop Recording
          </WundaButton>
        </Box>
      </Modal>
    </>
  );
};

WundaVoiceRecorder.propTypes = {
  onUpload: PropTypes.func.isRequired,
  uploadedFile: PropTypes.func.isRequired,
};

export default WundaVoiceRecorder;
