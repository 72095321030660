import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import WundaButton from './WundaButton';

function InstallPWAButton() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [installPromptEvent, setInstallPromptEvent] = useState(null);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Stash the event so it can be triggered later.
      setInstallPromptEvent(e);
    });
  }, []);

  const handleInstall = () => {
    if (!installPromptEvent) return;
    installPromptEvent.prompt(); // Show the install prompt
    installPromptEvent.userChoice.then((userChoice) => {
      console.log('PWA install user choice: ', JSON.stringify(userChoice));
      // Clear the saved prompt since it can't be used again
      setInstallPromptEvent(null);
    });
  };

  if (!isMobile || !installPromptEvent) {
    return null;
  }

  return (
    <WundaButton
      variant="outlined"
      color="secondary"
      size="small"
      onClick={handleInstall}
      disabled={!installPromptEvent}
    >
      Install App
    </WundaButton>
  );
}

export default InstallPWAButton;
