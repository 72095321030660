import PropTypes from 'prop-types';
import { Typography, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

VerticalTextDrawer.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default function VerticalTextDrawer({ text, url }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleClick = () => {
    navigate(url);
  };

  if (!matches) {
    return null; // don't render the component on small screens
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        position: 'fixed',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        width: 35,
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        overflow: 'auto',
        cursor: 'pointer',
        zIndex: 1300, // adjust as needed
        color: theme.palette.secondary.contrastText, // adjust as needed
        backgroundColor: theme.palette.secondary.main, // adjust as needed
        borderRadius: '0 10px 10px 0', // rounded corners on the right side
        '&:hover': {
          backgroundColor: theme.palette.primary.main, // adjust as needed
          color: theme.palette.primary.contrastText, // adjust as needed
        },
      }}
    >
      <Typography
        variant="body1"
        sx={{ writingMode: 'vertical-lr', textOrientation: 'sideways', transform: 'rotate(180deg)' }}
      >
        {text}
      </Typography>
    </Box>
  );
}
