import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ErrorMessage = ({ message, setMessage }) => {
    const [visible, setVisible] = useState(!!message);

    useEffect(() => {
        if (message) {
            setVisible(true);
            const timer = setTimeout(() => {
                setVisible(false);
                setMessage(null); // Reset the message to null
            }, 10000); // 10 seconds

            // Clear timeout if the component is unmounted or if message changes
            return () => clearTimeout(timer);
        }
        // Add this return statement
        return undefined;
    }, [message, setMessage]); // Add setMessage to the dependency array

    if (!visible) {
        return null;
    }

    return (
        <Alert severity="error" icon={<ErrorOutlineIcon color="error" style={{ fontSize: 30 }} />}>
            {message}
        </Alert>
    );
};

ErrorMessage.propTypes = {
    message: PropTypes.string,
    setMessage: PropTypes.func, // Add setMessage to PropTypes
};

export default ErrorMessage;