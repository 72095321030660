import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, Stack, Typography, Link } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import { AuthContext } from '../../../providers/AuthProvider';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import ReferralSidebar from '../../../components/wundamental/ReferralSidebar';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import InstallPWAButton from '../../../components/wundamental/InstallPWAButton';
//
import packageJson from '../../../../package.json';
import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { currentUser } = useContext(AuthContext);
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box
        sx={{
          position: 'absolute', // Add this line
          bottom: 0, // Add this line
          width: '100%', // Add this line
          mt: 10,
          mb: 1,
        }}
      >
        <Stack alignItems="center" sx={{ borderRadius: 2, position: 'relative' }}>
          {!isDesktop && <InstallPWAButton />}
          <Iconify icon="clarity:beta-solid" width={40} height={40} />
          <Typography variant="caption" align="center" color="text.secondary">
            Version: {packageJson.version}
          </Typography>
          <Typography variant="caption" align="center" color="text.secondary">
            © {format(new Date(), 'yyyy')} Wundamental. All rights reserved.
          </Typography>
          <Typography variant="caption" align="center" color="text.secondary">
            <Link href="https://wundamental.ai/terms-of-service/" color="inherit">
              Terms of Service
            </Link>
            {' | '}
            <Link href="https://wundamental.ai/privacy-policy/" color="inherit">
              Privacy Policy
            </Link>
          </Typography>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {process.env.REACT_APP_REFERRAL_ENABLED === 'true' && currentUser.DBuser?.canRefer && (
        <ReferralSidebar text="GIVE 5 SESSIONS, GET €10" url="/home/referral" />
      )}
    </Box>
  );
}
