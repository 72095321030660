import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, LinearProgress, Stack, Tooltip } from '@mui/material';
import { Icon as Iconify } from '@iconify/react';

const WundaSpeakerRatio = ({ data }) => {
  const renderRoleRatio = (role, ratio, name, _id) => {
    const roleName = role.charAt(0).toUpperCase() + role.slice(1);
    const roleValue = ratio.toFixed(0);

    return (
      <Box key={`${_id}-${role}`} sx={{ width: '100%', mt: 0.5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0 }}>
            {roleName}: <span className="fs-mask">{name}</span>
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0 }}>
            {roleValue}%
          </Typography>
        </Box>
        {role.toLowerCase() === data.theme?.nurturerRole.toLowerCase() && (
          <LinearProgress variant="determinate" value={ratio} />
        )}
      </Box>
    );
  };

  const processRoleRatios = (roleRatios) => {
    if (!roleRatios || typeof roleRatios !== 'object') {
      return [];
    }
    return Object.entries(roleRatios)
      .sort(([roleA], [roleB]) => {
        if (roleA === data.theme?.nurturerRole.toLowerCase()) return -1;
        if (roleB === data.theme?.nurturerRole.toLowerCase()) return 1;
        return 0;
      })
      .map(([role, ratio]) => {
        let name;
        const uniqueIdentifier = `${role}-${ratio}`;
        if (data.flourisherName && data.nurturerName) {
          if (role === data.theme?.nurturerRole.toLowerCase()) {
            name = `${data.nurturerName}`;
          } else if (role === data.theme?.flourisherRole.toLowerCase()) {
            name = `${data.flourisherName}`;
          }
        }

        return renderRoleRatio(role, ratio, name, uniqueIdentifier);
      });
  };

  return (
    <Stack sx={{ mt: 1, width: '20%', minWidth: '200px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Typography variant="body2" color="text.secondary">
          <strong>Speaking ratio</strong>
        </Typography>
        <Tooltip title="Coach/coachee ideal ratio of speaking is roughly 20:80. Mentor/mentee ideal ratio of speaking is roughly 40:60.">
          <Iconify icon="mdi-info" sx={{ fontSize: '1rem', ml: 0.5 }} />
        </Tooltip>
      </Box>
      {data.transcript &&
        (Array.isArray(data.roleMappings) && data.roleMappings.length > 0
          ? data.roleMappings
              .filter((mapping) => mapping.themeID === data.themeID)
              .flatMap(({ roleRatios }) => processRoleRatios(roleRatios))
          : data.transcript?.roleRatios && typeof data.transcript?.roleRatios === 'object'
            ? processRoleRatios(data.transcript?.roleRatios)
            : null)}
    </Stack>
  );
};

WundaSpeakerRatio.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WundaSpeakerRatio;
