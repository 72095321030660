// component
import { Transcribe, ExpandMore } from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EventNoteIcon from '@mui/icons-material/EventNote';
import MicIcon from '@mui/icons-material/Mic';
import Iconify from '../../../components/iconify/Iconify';
// import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/home/app',
  //   icon: <Analytics />,
  // },
  {
    title: 'sessions',
    path: '/home/sessions',
    icon: <EventNoteIcon />,
  },
  {
    title: 'consents',
    path: '/home/consent',
    icon: <Iconify icon={'material-symbols:approval-delegation-outline-sharp'} />,
  },
  {
    title: 'recorder',
    path: '/home/recorder',
    icon: <MicIcon />,
    feature: 'advanced',
    dividerAfter: true,
  },
  {
    title: 'Advanced features',
    icon: <ExpandMore />,
    feature: 'advanced',
    children: [
      {
        title: 'recordings',
        path: '/home/recordings',
        icon: <MicIcon />,
      },
      {
        title: 'transcripts',
        path: '/home/transcripts',
        icon: <Transcribe />,
      },
      {
        title: 'reports',
        path: '/home/reports',
        icon: <SummarizeIcon />,
      },
    ],
  },
  // {
  //   title: 'products',
  //   path: '/home/products',
  //   icon: <SummarizeIcon />,
  // },
  // {
  //   title: 'blog',
  //   path: '/home/blog',
  //   icon: <SummarizeIcon />,
  // },
  // {
  //   title: 'templates',
  //   path: '/dashboard/templates',
  //   icon: <FileCopy />,
  // },
];

export default navConfig;
