import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from '../iconify';

const Loader = ({ label = 'Loading...' }) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" p={2}>
      <Iconify icon={'svg-spinners:blocks-wave'} width={50} height={50} color="#D4FF51" />
      <Typography variant="h6" sx={{ ml: 2, color: theme.palette.text.primary }}>
        {label}
      </Typography>
    </Box>
  );
};

Loader.propTypes = {
  label: PropTypes.string,
};

export default Loader;
